import { gql } from '@apollo/client';

export default gql`
  subscription contentChat($ids: String) {
    updateContentChat(contentIds: $ids) {
      mId
      mRefId
      mType
      mTitle
      mContent
      mCreatedAt
      mUpdatedAt
      mCreatedById
      mUpdatedById
      convoType
      contentIds
    }
  }
`;
