/* eslint-disable no-param-reassign,@typescript-eslint/ban-ts-comment */
import React, { memo, ReactEventHandler, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PlayIcon from 'features/editor/assets/icons/Play_WithCircleBackground.svg';
import DefaultThumnail from 'features/editor/assets/images/defaultThumbnail.png';
import Placeholder from 'features/editor/assets/images/Placeholders.png';
import { mediaTypes } from 'features/editor/constants/types/mediaTypes';
import GET_SIGNED_URL from 'features/editor/graphql/query/getSignedUrl';
import getThumbnailKey from 'features/editor/utils/getThumbnailKey';

import { Icon, Thumbnail, ThumbnailWrapper } from './styled';

const onImageLoadingError: ReactEventHandler<HTMLImageElement> = (event) => {
  // @ts-ignore
  event.target.onerror = null;
  // @ts-ignore
  event.target.src = DefaultThumnail;
};

const ThumbnailView = ({ assets }: { assets: Record<string, string>[] }) => {
  const [assetItem] = assets;
  const { mId, mRefId } = assetItem || {};

  const placeholder = useMemo(
    () => assets.find(({ mediaType }) => mediaType === 'video/placeholder'),
    [assets],
  );
  const hasPlaceholder = Boolean(placeholder);

  const memoizedKey = useMemo(
    () => mId && mRefId && getThumbnailKey(mId, mRefId),
    [mId, mRefId],
  );

  const { data } = useQuery(GET_SIGNED_URL, {
    variables: {
      input: {
        key: memoizedKey,
      },
    },
    skip: !memoizedKey,
  });

  const placeholderThumbnail = data?.getSignedUrl;

  return assetItem ? (
    <div>
      {hasPlaceholder && (
        <ThumbnailWrapper>
          {placeholderThumbnail ? (
            <Thumbnail alt="placeholder" src={placeholderThumbnail} />
          ) : (
            <Icon src={Placeholder} alt="placeholder" />
          )}
        </ThumbnailWrapper>
      )}
      {!hasPlaceholder && assetItem?.assetType !== mediaTypes.GRAPHICS && (
        <ThumbnailWrapper>
          <Thumbnail
            src={assetItem?.thumbnailUrl}
            alt="asset"
            onError={onImageLoadingError}
          />
          <Icon alt="media-controls" src={PlayIcon} />
        </ThumbnailWrapper>
      )}
    </div>
  ) : null;
};

export default memo(ThumbnailView);
