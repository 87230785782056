import React from 'react';
import ReactDOM from 'react-dom';
import {
  css,
  Global,
  ThemeProvider as EmotionThemeProvider,
} from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import App from './App';
import theme, { MuiTheme } from './theme';

const globalStyles = css`
  body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: ${theme.colors.appBackgroundMain};
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={MuiTheme}>
      <Global styles={globalStyles} />
      <EmotionThemeProvider theme={theme}>
        <App />
      </EmotionThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
