import styled from '@emotion/styled';

export const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const MessagesWrapper = styled('div')`
  flex: 1;
  padding-block: 8px;
  overflow-y: auto;
  display: flex;
  /* flex-direction: column-reverse; */
`;

export const InputWrapper = styled('div')`
  padding: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.dividerLight};
  margin-bottom: 24px;
`;
