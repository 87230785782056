import { elementTypes } from './types';

const { UNORDERED_LIST, LIST_ITEM, ORDERED_LIST } = elementTypes;

const components = {
  [UNORDERED_LIST]: 'ul',
  [LIST_ITEM]: 'li',
  [ORDERED_LIST]: 'ol',
} as const;

export default components;
