import styled from '@emotion/styled';

export const ThumbnailWrapper = styled('div')`
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Thumbnail = styled('img')`
  width: 32px;
  height: 32px;
  user-select: none;
`;

export const Icon = styled('img')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
