const addLeadingZeroes = (num: number) => num.toString().padStart(2, '0');

/**
 * Converts milliseconds to hh:mm:ss formatted time string
 *
 * @param  milliseconds Milliseconds to be converted
 * @param  preserveSign If true, adds +/- before the formatted time
 * @returns  Formatted time string hh:mm:ss
 */

const getTimeString = (milliseconds = 0, preserveSign = false) => {
  const parsedMilliseconds = Number(milliseconds);

  if (Number.isNaN(parsedMilliseconds) || parsedMilliseconds === 0)
    return '00:00';

  const sign = Math.sign(parsedMilliseconds) === -1 ? '-' : '+';
  const parsedSeconds = Math.floor(Math.abs(parsedMilliseconds / 1000));
  const hours = Math.floor(parsedSeconds / 3600);
  const minutes = Math.floor((parsedSeconds - hours * 3600) / 60);
  const seconds = parsedSeconds - hours * 3600 - minutes * 60;
  const hh = hours ? `${addLeadingZeroes(hours)}:` : '';
  const mm = `${addLeadingZeroes(minutes)}:`;
  const ss = `${addLeadingZeroes(seconds)}`;
  const formattedTime = hh + mm + ss;

  return (preserveSign ? sign : '') + formattedTime;
};

export default getTimeString;
