import { IMessageProps } from 'types/appTypes';

import MessageInput from './components/messageInput';
import Messages from './components/messages';
import { Container, InputWrapper, MessagesWrapper } from './styled';

function View(props: Readonly<IMessageProps>) {
  return (
    <Container>
      <MessagesWrapper>
        <Messages {...props} />
      </MessagesWrapper>
      <InputWrapper>
        <MessageInput {...props} />
      </InputWrapper>
    </Container>
  );
}

export default View;
