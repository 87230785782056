import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import GET_SIGNED_URL from 'features/editor/graphql/query/getSignedUrl';

import { IEditorValue } from '../utils';

const useFetchContent = (mContentKey: string) => {
  const [contentLoading, setContentLoading] = useState(true);
  const [content, setContent] = useState<IEditorValue | null>(null);

  const { data, loading, error } = useQuery(GET_SIGNED_URL, {
    variables: {
      input: {
        key: mContentKey,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const fetchData = useCallback(async () => {
    try {
      if (data?.getSignedUrl) {
        fetch(data?.getSignedUrl)
          .then((response) => response.json())
          .then((rData) => {
            setContent(rData);
            setContentLoading(false);
          })
          .catch(() => {
            setContentLoading(false);
          });
      } else {
        setContentLoading(false);
      }
    } catch (e) {
      // console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getSignedUrl]);

  useEffect(() => {
    if (data) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return [content, loading || contentLoading, error] as const;
};

export default useFetchContent;
