import styled from '@emotion/styled/macro';

import { borderColor, edgeColor } from '../../utils/styleUtils';

interface LeftEdgeProps {
  isAuto: boolean;
  isGraphic: boolean;
  isSelected: boolean;
}

export const LeftEdge = styled('div')<LeftEdgeProps>`
  width: 8px;
  height: 100%;
  background-color: ${(props) =>
    props.isAuto ? edgeColor(props.isGraphic) : 'transparent'};
  border-top-left-radius: 4px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-top-left-radius: 4px;
    border-top: ${borderColor};
    border-left: ${borderColor};
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom: ${borderColor};
    border-left: ${borderColor};
  }
`;

export const LeftEdgeWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;
