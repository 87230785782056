import { elementTypes } from 'features/editor/constants/types';
import { Editor, Element as SlateElement, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

const isLink = ({ type }: { type: string }) => type === elementTypes.LINK;

/**
 * Whether the link is currently active on the editor
 *
 * @param editor SlateJS Editor Instance
 * @returns true/false
 */

const isLinkActive = (editor: ReactEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) && SlateElement.isElement(node) && isLink(node),
  });
  return !!link;
};

/**
 * Wraps the link text with URL
 *
 * @param editor SlateJS editor instance
 * @param href URL of the link
 * @param linkText Optional link text
 */

const wrapLink = (editor: ReactEditor, href: string, linkText?: string) => {
  if (isLinkActive(editor)) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link = {
    type: 'link',
    data: { href },
    children: isCollapsed ? [{ text: linkText || href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export default wrapLink;
