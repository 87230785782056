export const getWords = (string: string) => string.split(/[ -]+/);
export const getFirstChar = (string: string) => string.charAt(0);
export const getUppercasedChars = (array: string[]) =>
  array.map((char) => char.toUpperCase());

export const getNChars = (words: string[], n: number) => {
  const numberOfWords = words.length;

  if (numberOfWords === 1) {
    const [onlyWord] = words;
    return Array.from(onlyWord).slice(0, n);
  }

  if (numberOfWords > n) {
    const firstChar = getFirstChar(words[0]);

    if (n === 1) return getUppercasedChars([firstChar]);

    const lastChar = getFirstChar(words[numberOfWords - 1]);

    if (n === 2) return getUppercasedChars([firstChar, lastChar]);

    const middleChars = words.slice(1, n - 1).map(getFirstChar);
    const arrayOfChars = [firstChar, ...middleChars, lastChar];

    return getUppercasedChars(arrayOfChars);
  }

  const nWords = words.slice(0, n);
  const arrayOfChars = nWords.map(getFirstChar);

  return getUppercasedChars(arrayOfChars);
};

const getInitials = (string: string, size = 3) => {
  if (!string) return null;

  const words = getWords(string);
  const chars = getNChars(words, size);

  return chars.join('');
};

export default getInitials;
