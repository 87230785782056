import { memo, useMemo } from 'react';
import { ReactComponent as AudioIcon } from 'features/editor/assets/icons/audio.svg';
import { ReactComponent as DocIcon } from 'features/editor/assets/icons/text_off.svg';
import { elementTypes } from 'features/editor/constants/types';
import getThumbnailKey from 'features/editor/utils/getThumbnailKey';
import { RenderElementProps } from 'slate-react';

import Box, { ContentWrapper, Title, TitleWrapper } from '../box';
import Thumbnail from '../image/components/thumbnail';
import { StyledImageIcon } from '../image/styled';
import { StyledVideoIcon } from '../video/styled';

const DEFAULT_MEDIA_TYPE = 'video/placeholder';

type iconType =
  | typeof StyledVideoIcon
  | React.FC<React.SVGProps<SVGSVGElement>>;

const ICONS: Record<string, iconType> = {
  video: StyledVideoIcon,
  image: StyledImageIcon,
  application: DocIcon,
  audio: AudioIcon,
};

const Placeholder = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.PLACEHOLDER, children: [] },
}: RenderElementProps) => {
  const { data } = element;

  const { mId, mRefId, mTitle, mediaType = DEFAULT_MEDIA_TYPE } = data || {};

  const [type] = mediaType.split('/');

  const thumbnailKey = getThumbnailKey(mId, mRefId);

  const Icon = useMemo(() => {
    return ICONS[type];
  }, [type]);

  return (
    <div {...attributes}>
      {children}
      <Box Icon={Icon} type="media">
        <ContentWrapper>
          <Thumbnail
            thumbnailKey={thumbnailKey}
            variant={elementTypes.PLACEHOLDER}
            thumbnailFor={type}
          />
          <TitleWrapper>
            <Title truncate noOfLines={3}>
              {mTitle}
            </Title>
          </TitleWrapper>
        </ContentWrapper>
      </Box>
    </div>
  );
};

export default memo(Placeholder);
