import React, { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import { CustomData } from '../../viewTypes';
import AutomationIcon from './components/automationIcon';
import Thumbnail from './components/thumbnail';
import Title from './components/title';
import Transition from './components/transition';
import getInitialData from './utils/getInitialData';

const AutomationWrapper = styled('div')`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.borderResting};
  background-color: ${(props) => props.theme.colors.backgroundResting};
  box-shadow: ${(props) => `
   0 0 1px ${props.theme.colors.boxShadow}, 0 2px 2px ${props.theme.colors.boxShadow}
  `};
  border-radius: 4px;
  overflow: hidden;
`;

const RootWrapper = styled('div')`
  padding: 8px;
`;

const PrimaryAutomation = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.CAMERA, children: [] },
}: RenderElementProps) => {
  const { type, data } = element;

  const initialData = useMemo(
    () => getInitialData(data as NonNullable<CustomData>),
    [data],
  );

  return (
    <RootWrapper contentEditable={false} {...attributes}>
      {children}
      <AutomationWrapper>
        <AutomationIcon type={type} />
        <Transition transitionValue={initialData.transition} />
        <Title
          meta={initialData.metaData}
          asset={initialData.assets[0]}
          templateVariant={initialData.templateVariant}
        />
        <Thumbnail assets={initialData.assets} />
      </AutomationWrapper>
    </RootWrapper>
  );
};

export default memo(PrimaryAutomation);
