import { ReactEditor } from 'slate-react';

import getMark from './getMark';

/**
 * Determines if specified mark type is active or not
 *
 * @param editor SlateJS editor instance
 * @param type Type for the mark to check for
 * @returns Boolean
 */

const isMarkActive = (editor: ReactEditor, type: string) =>
  Boolean(getMark(editor, type));

export default isMarkActive;
