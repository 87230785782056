import React, { useMemo } from 'react';

import iconComponents from '../../constants/iconComponents';

interface AutomationIconProps {
  type: string;
}

const AutomationIcon = ({ type }: AutomationIconProps) => {
  const IconComponent = useMemo(() => iconComponents[type], [type]);

  return <IconComponent />;
};

export default AutomationIcon;
