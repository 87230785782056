import { memo } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps } from 'slate-react';

const HorizontalRuleWrapper = styled('div')`
  padding: 4px 8px;
`;

const StyledHorizontalRule = styled('hr')`
  border-color: ${(props) => props.theme.colors.dividerLight};
  border-style: solid;
`;

const HorizontalRule = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
}: RenderElementProps) => (
  <div {...attributes}>
    {children}
    <HorizontalRuleWrapper contentEditable={false}>
      <StyledHorizontalRule />
    </HorizontalRuleWrapper>
  </div>
);

export default memo(HorizontalRule);
