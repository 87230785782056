import { memo } from 'react';
import { ReactComponent as OverlineIcon } from 'features/editor/assets/icons/trumpet.svg';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const Overline = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.OVERLINE, children: [] },
}: RenderElementProps) => {
  const { data } = element;
  const content = data?.content || '';
  return (
    <div {...attributes}>
      {children}
      <Box Icon={OverlineIcon}>
        <RootWrapper>
          <CollapsedLabel>Overline</CollapsedLabel>
          {content ? (
            <Title>{content}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(Overline);
