import React, { memo } from 'react';

import { inTimingTypes } from '../../../../utils/timingTypes';
import { LeftEdge, LeftEdgeWrapper } from './styled';

const { MANUAL_IN, AUTO_IN } = inTimingTypes;

interface InIconProps {
  inTimingType: string;
  isGraphic: boolean;
  isSelected: boolean;
}

const InIconView = ({
  inTimingType = MANUAL_IN,
  isGraphic = false,
  isSelected = false,
}: InIconProps) => {
  const isAuto = inTimingType === AUTO_IN;

  return (
    <LeftEdgeWrapper>
      <LeftEdge isAuto={isAuto} isGraphic={isGraphic} isSelected={isSelected} />
    </LeftEdgeWrapper>
  );
};
export default memo(InIconView);
