import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactComponent as LeftPointerIcon } from 'assets/icons/speechbubble_left.svg';
import { ReactComponent as RightPointerIcon } from 'assets/icons/speechbubble_right.svg';

interface OwnerProps {
  isOwner?: boolean;
  isErrorMessage?: boolean;
}

export const Container = styled('div')<OwnerProps>`
  padding-left: ${({ isOwner }) => (isOwner ? '72px' : '16px')};
  padding-right: ${({ isOwner }) => (isOwner ? '20px' : '48px')};
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const MessageRow = styled('div')`
  display: flex;
  align-items: flex-end;
  position: relative;
`;

export const SenderWrapper = styled('div')`
  ${({ theme }) => theme.typography.body2};
  width: 30px;
  height: 28px;
  background-color: ${({ theme }) => theme.colors.surfaceCard};
  border-radius: 14px;
  line-height: 28px;
  text-align: center;
  margin-right: 6px;
  overflow: hidden;
`;

export const SenderImg = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Bubble = styled('div')<OwnerProps>`
  position: relative;
  width: 100%;
  border-radius: 8px;
  min-width: 28px;
  border: 1px solid ${({ theme }) => theme.colors.buttonBorderOutlined};
  ${({ theme, isOwner, isErrorMessage }) => {
    if (isErrorMessage) {
      return css`
        background-color: ${theme.colors.errorBackground};
      `;
    }
    return css`
      background-color: ${isOwner ? '#4F3C7A' : '#1f2937'};
    `;
  }};
`;

export const IconMessage = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  & svg {
    margin-right: 0.25rem;
    fill-opacity: 0.5;
  }
`;

export const LeftPointer = styled(LeftPointerIcon)<{
  isErrorMessage?: boolean;
}>`
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate3d(-7px, 1px, 0);
  & path:nth-of-type(1) {
    fill: ${({ theme, isErrorMessage }) => {
      if (isErrorMessage) {
        return theme.colors.errorBackground;
      }
      return '#1f2937';
    }};
    fill-opacity: 1;
  }
  & path:nth-of-type(2) {
    fill: ${({ theme }) => theme.colors.buttonBorderOutlined};
    fill-opacity: 1;
  }
`;

export const RightPointer = styled(RightPointerIcon)<{
  isErrorMessage?: boolean;
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate3d(7px, 1px, 0);
  & path:nth-of-type(1) {
    fill: ${({ theme, isErrorMessage }) => {
      if (isErrorMessage) {
        return theme.colors.errorBackground;
      }
      return '#4f3c7a';
    }};
    fill-opacity: 1;
  }
  & path:nth-of-type(2) {
    fill: ${({ theme }) => theme.colors.buttonBorderOutlined};
    fill-opacity: 1;
  }
`;

export const InfoWrapper = styled('div')<OwnerProps>`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.mediumEmphasis};
  display: flex;
  align-items: center;
  gap: 4px;
  ${({ isOwner }) =>
    isOwner
      ? css`
          justify-content: flex-end;
        `
      : css`
          margin-left: 32px;
        `};
`;

export const VerticalDivider = styled('div')`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.dividerLight};
`;

interface TextButtonProp {
  confirm?: boolean;
}
export const TextButton = styled('button')<TextButtonProp>`
  ${({ theme }) => theme.typography.caption};
  height: 100%;
  border: none;
  color: ${({ confirm, theme }) =>
    confirm ? theme.colors.statusError : 'inherit'};
  background-color: inherit;
  padding: 0 4px;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
`;
