import { memo, useMemo } from 'react';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import { CustomData } from '../../viewTypes';
import Box from './components/box';
import InTiming from './components/inTiming';
import OutTiming from './components/outTiming';
import Title from './components/title';
import iconComponents from './constants/iconComponents';
import { ContentWrapper, RootWrapper, SelectWrapper } from './styled';
import getInitialData from './utils/getInitialData';
import { outTimingTypes } from './utils/timingTypes';

const { MANUAL_OUT } = outTimingTypes;

const { OVERLAY_GRAPHICS } = elementTypes;

const SecondaryAutomation = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.ACCESSORY, children: [] },
}: RenderElementProps) => {
  const { data, type } = element;

  const Icon = iconComponents[type];

  const initialData = useMemo(
    () => getInitialData(data as NonNullable<CustomData>),
    [data],
  );

  const {
    inTiming,
    outTiming,
    startTime,
    duration,
    protocol,
    templateVariant,
    starcg,
  } = initialData;

  const isGraphic = type === OVERLAY_GRAPHICS;

  return (
    <RootWrapper {...attributes} contentEditable={false}>
      {children}
      <Box
        inTimingType={inTiming}
        outTimingType={isGraphic ? outTiming : MANUAL_OUT}
        isGraphic={isGraphic}
      >
        <ContentWrapper>
          <SelectWrapper>
            <InTiming inTiming={inTiming} startTime={startTime} />
            {isGraphic && (
              <OutTiming outTiming={outTiming} duration={duration as number} />
            )}
          </SelectWrapper>
          <Title
            protocol={protocol}
            templateVariant={templateVariant}
            starcg={starcg}
          />
          <Icon />
        </ContentWrapper>
      </Box>
    </RootWrapper>
  );
};

export default memo(SecondaryAutomation);
