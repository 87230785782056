import React, { memo, MouseEventHandler, useCallback, useMemo } from 'react';
import { elementTypes } from 'features/editor/constants/types';
import { useSlate } from 'slate-react';

import ButtonBase from '../base';
import iconComponents from './constants/iconComponents';
import { isBlockActive, toggleBlock } from './utils';

interface IBlockButton {
  type: string;
}

const BlockButton = ({ type = elementTypes.HEADING_TWO }: IBlockButton) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, type);

  const onMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      toggleBlock(editor, type, isActive);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, isActive],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const IconComponent = useMemo(() => iconComponents[type], []);

  return (
    <ButtonBase
      isActive={isActive}
      IconComponent={IconComponent}
      onMouseDown={onMouseDown}
    />
  );
};

export default memo(BlockButton);
