import { useMutation } from '@apollo/client';

import CREATE_MESSAGE from '../graphql/createMessage';
import DELETE_MESSAGE from '../graphql/deleteMessage';
import GET_MESSAGES_OF_CONVERSATION from '../graphql/getConversations';

const useCreateMessage = () => {
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [deleteMessage] = useMutation(DELETE_MESSAGE);
  const now = new Date().toISOString();

  const create = async ({
    userId,
    mId,
    mContent = '',
    convoType,
    mRefId = null,
    crudAction = 'CREATE',
  }) => {
    if (crudAction === 'DELETE') {
      try {
        await deleteMessage({
          variables: {
            input: {
              mId,
              mRefId,
              crudAction,
            },
          },
          update: (cache) => {
            const { getMessagesOfConversation } = cache.readQuery({
              query: GET_MESSAGES_OF_CONVERSATION,
              variables: {
                input: {
                  mId,
                },
                limit: 25,
              },
            });
            const items = getMessagesOfConversation?.items || [];
            const newItems = items.filter(
              (message) => message.mRefId !== mRefId,
            );
            const newGetMessagesOfConversation = {
              ...getMessagesOfConversation,
            };
            newGetMessagesOfConversation.items = newItems;

            cache.writeQuery({
              query: GET_MESSAGES_OF_CONVERSATION,
              variables: {
                input: {
                  mId,
                },
                limit: 25,
              },
              data: {
                getMessagesOfConversation: newGetMessagesOfConversation,
              },
            });
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteMessage: {
              mId,
              __typename: 'MessageType',
            },
          },
        });
      } catch (e) {
        // logger.error(e);
      }
    } else {
      try {
        await createMessage({
          variables: {
            input: {
              mId,
              mContent,
              convoType,
            },
          },
          update: (cache, mutationResult) => {
            const { createMessage: createMessageResult } = mutationResult.data;
            const { getMessagesOfConversation } = cache.readQuery({
              query: GET_MESSAGES_OF_CONVERSATION,
              variables: {
                input: {
                  mId,
                },
                limit: 25,
              },
            });
            const { items } = getMessagesOfConversation;
            const isFound = items.find(
              (message) => message.mRefId === createMessageResult.mRefId,
            );
            const newMessageList = isFound
              ? items
              : [createMessageResult, ...items];
            cache.writeQuery({
              query: GET_MESSAGES_OF_CONVERSATION,
              variables: {
                input: {
                  mId,
                },
                limit: 25,
              },
              data: {
                getMessagesOfConversation: {
                  ...getMessagesOfConversation,
                  items: newMessageList,
                },
              },
            });
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createMessage: {
              mId,
              mRefId: now,
              mTitle: '',
              mCreatedAt: now,
              mUpdatedAt: now,
              mContent,
              convoType,
              mType: 'message',
              mCreatedById: userId,
              mUpdatedById: userId,
              __typename: 'MessageType',
            },
          },
        });
      } catch (e) {
        // logger.error(e);
      }
    }
  };

  return [create];
};

export default useCreateMessage;
