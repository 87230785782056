import React, { memo } from 'react';

import { inTimingTypes, outTimingTypes } from '../../utils/timingTypes';
import InIcon from './components/inIcon';
import OutIcon from './components/outIcon';
import { ChildWrapper, MainWrapper } from './styled';

const { MANUAL_IN } = inTimingTypes;
const { MANUAL_OUT } = outTimingTypes;

interface BoxProps {
  children: React.ReactNode;
  inTimingType: string;
  outTimingType: string;
  isGraphic: boolean;
  isSelected?: boolean;
}

const Box = ({
  children = null,
  inTimingType = MANUAL_IN,
  outTimingType = MANUAL_OUT,
  isGraphic,
  isSelected = false,
}: BoxProps) => (
  <MainWrapper isSelected={isSelected}>
    <InIcon
      isGraphic={isGraphic}
      inTimingType={inTimingType}
      isSelected={isSelected}
    />
    <ChildWrapper isSelected={isSelected} isGraphic={isGraphic}>
      {children}
    </ChildWrapper>
    <OutIcon
      isGraphic={isGraphic}
      isSelected={isSelected}
      outTimingType={outTimingType}
    />
  </MainWrapper>
);

export default memo(Box);
