/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMessageProps } from 'types/appTypes';

import Editor from '../../../editor/view';
import useCreateMessage from '../../hooks/useCreateMessage';
import { InputWrapper } from './styled';

const allowedTypes = new Set(['story', 'rundown', 'pitch', 'space']);

const shouldEnableMention = (conversationType: string | undefined) =>
  allowedTypes.has(conversationType as string);

function MessageInput({ conversation, user }: Readonly<IMessageProps>) {
  const [createMessage] = useCreateMessage();

  // @ts-expect-error mType for backward compatibility (remove when mobile is updated)
  const convoType = conversation?.convoType ?? conversation?.mType;

  const handleCreateMessage = async (value: any) => {
    try {
      await createMessage({
        userId: user?.mId,
        mId: conversation?.mId,
        mContent: JSON.stringify(value),
        convoType,
      });
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <InputWrapper focused>
      <Editor
        user={user}
        variant="message"
        fullHeight={false}
        readOnly={false}
        placeholder="Type something..."
        enableMention={shouldEnableMention(convoType)}
        onDone={handleCreateMessage}
      />
    </InputWrapper>
  );
}

export default MessageInput;
