import { memo } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps } from 'slate-react';

const StyledQuote = styled('blockquote')`
  margin: 0;
  margin: 8px;
  padding: 8px;
  border-radius: 2px;
  border-left: ${(props) => `4px solid ${props.theme.colors.dividerLight}`};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  background-color: ${(props) => props.theme.colors.quoteBackground};

  ::before {
    content: open-quote;
  }
  ::after {
    content: close-quote;
  }
`;

const BlockQuote = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
}: RenderElementProps) => {
  return <StyledQuote {...attributes}>{children}</StyledQuote>;
};

export default memo(BlockQuote);
