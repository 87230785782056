import { Editor, Element as SlateElement } from 'slate';
import { ReactEditor } from 'slate-react';

/**
 * Determines if specified block button is active or not
 *
 * @param  editor SlateJS editor instance
 * @param  type Type for the node to check for
 * @returns boolean value of whether the block button is active or not
 */

const isBlockActive = (editor: ReactEditor, type: string) => {
  const { selection } = editor;

  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
    }),
  );

  return !!match;
};

export default isBlockActive;
