/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLayoutEffect, useState } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import AccessDeniedPage from 'features/accessDenied';
import DailyNote from 'features/dailyNote';
import Editor from 'features/editor';
import MessageHub from 'features/messageHub';
import { IClientConfig } from 'types/appTypes';

const ViewComponent = ({ clientConfig }: { clientConfig: IClientConfig }) => {
  if (clientConfig.instance) return <Editor instance={clientConfig.instance} />;

  if (clientConfig.dailyNote)
    return <DailyNote dailyNote={clientConfig.dailyNote} />;

  return (
    <MessageHub
      conversation={clientConfig.conversation}
      user={clientConfig.user}
    />
  );
};

const App = () => {
  const [clientConfig, setClientConfig] = useState<IClientConfig>();

  useLayoutEffect(() => {
    const handleEvent = ({ data }: { data: IClientConfig }) => {
      if (data) {
        setClientConfig(data);
      }
    };

    // @ts-ignore
    document.addEventListener('message', handleEvent);

    // @ts-ignore
    return () => document.removeEventListener('message', handleEvent);
  }, []);

  if (!clientConfig?.amplifyToken) return <AccessDeniedPage />;

  const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: clientConfig.amplifyToken,
  };

  const endpoint = clientConfig?.endpoint;
  const region = clientConfig?.region;

  const HttpLink = createHttpLink({
    uri: endpoint,
  });

  const client = new ApolloClient({
    link: from([
      createAuthLink({
        url: endpoint,
        auth,
        region,
      }),
      setContext(async (_, previousContext) => {
        const { sub, claims } = clientConfig.headerToken;
        return {
          headers: {
            ...previousContext.headers,
            claims,
            sub,
          },
        };
      }),
      split(
        (op) => {
          const { operation } = op.query.definitions[0] as any;
          if (operation === 'subscription') {
            return false;
          }
          return true;
        },
        HttpLink,
        createSubscriptionHandshakeLink(
          {
            auth,
            region,
            url: endpoint,
          },
          HttpLink,
        ),
      ),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: (obj) => {
        const { mId, mRefId } = obj;
        if (!mId) return defaultDataIdFromObject(obj);
        return mId !== mRefId ? `${mId}:${mRefId}` : (mId as string);
      },
    }),
  });

  if (!client) return <AccessDeniedPage />;

  return (
    <ApolloProvider client={client}>
      <ViewComponent clientConfig={clientConfig} />
    </ApolloProvider>
  );
};

export default App;
