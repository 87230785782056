/**
 * Wraps editor with overridden void plugin functionalites
 *
 * @param mId mId of the thumbnail
 * @param mRefId mRefId of the thumbnail
 * @returns string that is constructed from mId and mRefId
 */

const getThumbnailKey = (mId?: string, mRefId?: string): string | undefined => {
  if (!mId || !mRefId) return;
  // eslint-disable-next-line consistent-return
  return `${mId}/media/${mRefId}/thumb_${mRefId}.jpg`;
};

export default getThumbnailKey;
