import styled from '@emotion/styled';
import { ReactComponent as ArrowDownSmallSvg } from 'features/editor/assets/icons/toolbar/arrow_down_small.svg';
import { ReactComponent as ColorIconSvg } from 'features/editor/assets/icons/toolbar/colorSwatch_off.svg';

export const ColorIconWrapper = styled('div')`
  height: 32px;
  width: 44px;
  display: flex;
  align-items: center;
  border: ${(props) => `1px solid ${props.theme.colors.borderOutlined}`};
  background-color: ${(props) => props.theme.colors.buttonOutlined};
  border-radius: 6px;
`;

interface IColorIcon {
  $markcolor: string;
}
export const ColorIcon = styled(ColorIconSvg)<IColorIcon>`
  margin-left: 4px;
  & .colorBox {
    fill: ${(props) => props.$markcolor};
    fill-opacity: 1;
  }
`;

export const ArrowDownIcon = styled(ArrowDownSmallSvg)`
  margin-left: -4px;
`;
