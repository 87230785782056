/**
 *
 * @param list of objects that have value property, i.e: {value:'any'}
 * @returns stringy version of values of the list
 *
 */

const stringifyListWithValue = (list: Record<string, string>[] = []) =>
  list.reduce((acc, current) => `${acc}${acc ? ', ' : ''}${current.value}`, '');

export default stringifyListWithValue;
