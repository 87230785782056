import { memo } from 'react';
import { ReactComponent as FactBoxIcon } from 'features/editor/assets/icons/blocks_FactBox_off.svg';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const Fact = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.FACT, children: [] },
}: RenderElementProps) => {
  const { data } = element;
  const stinger = data?.stinger || '';
  return (
    <div {...attributes}>
      {children}
      <Box Icon={FactBoxIcon}>
        <RootWrapper>
          <CollapsedLabel>Fact Box</CollapsedLabel>
          {stinger ? (
            <Title>{`Stinger: ${stinger}`}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(Fact);
