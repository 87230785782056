import React, { memo } from 'react';
import getThumbnailKey from 'features/editor/utils/getThumbnailKey';
import { RenderElementProps } from 'slate-react';

import Box, { ContentWrapper, Description, Title, TitleWrapper } from '../box';
import Thumbnail from './components/thumbnail';
import { StyledImageIcon } from './styled';

const Image = ({ attributes, element, children }: RenderElementProps) => {
  const {
    mId,
    mRefId,
    src,
    title: generatedTitle,
    metadata,
  } = element.data ?? {};
  const { title, caption } = metadata ?? {};

  const key = src || getThumbnailKey(mId, mRefId);

  return (
    <div {...attributes}>
      {children}
      <Box type="media" Icon={StyledImageIcon}>
        <ContentWrapper>
          <Thumbnail thumbnailKey={key} />
          <TitleWrapper>
            {key ? (
              <Title>{title || generatedTitle}</Title>
            ) : (
              <Title italic>No Photo added yet...</Title>
            )}
            {caption && <Description>{caption}</Description>}
          </TitleWrapper>
        </ContentWrapper>
      </Box>
    </div>
  );
};

export default memo(Image);
