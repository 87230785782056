import { memo, MouseEventHandler, useCallback, useMemo } from 'react';
import { markTypes } from 'features/editor/constants/types';
import { useSlate } from 'slate-react';

import ButtonBase from '../base';
import iconComponents from './constants/iconComponents';
import { isMarkActive, toggleMark } from './utils';

interface IMarkButton {
  type: string;
}

const MarkButton = ({ type = markTypes.BOLD }: IMarkButton) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, type);

  const onMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      toggleMark(editor, type, isActive);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, isActive],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const IconComponent = useMemo(() => iconComponents[type], []);

  return (
    <ButtonBase
      isActive={isActive}
      IconComponent={IconComponent}
      onMouseDown={onMouseDown}
    />
  );
};

export default memo(MarkButton);
