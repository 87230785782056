import Author from '../components/author';
import BlockQuote from '../components/blockQuote';
import Description from '../components/description';
import EmbeddedTweet from '../components/embeddedTweet';
import EmbeddedVideo from '../components/embeddedVideo';
import Fact from '../components/fact';
import Gif from '../components/gif';
import Heading from '../components/heading';
import HorizontalRule from '../components/horizontalRule';
import Image from '../components/image';
import Link from '../components/link';
import List from '../components/list';
import ListItem from '../components/listItem';
import LiveStream from '../components/livestream';
import Mention from '../components/mention';
import Overline from '../components/overline';
import Paragraph from '../components/paragraph';
import Placeholder from '../components/placeholder';
import PrimaryAutomation from '../components/primaryAutomation';
import Privacy from '../components/privacy';
import Quote from '../components/quote';
import SecondaryAutomation from '../components/secondaryAutomation';
import Section from '../components/section';
import Source from '../components/source';
import Tags from '../components/tags';
import Title from '../components/title';
import TweetThread from '../components/tweetThread';
import Video from '../components/video';
import { elementTypes } from './types';

const {
  PARAGRAPH,
  TITLE,
  DESCRIPTION,
  HEADING_ONE,
  HEADING_TWO,
  HEADING_THREE,
  HEADING_FOUR,
  ORDERED_LIST,
  UNORDERED_LIST,
  LIST_ITEM,
  IMAGE,
  GIF,
  VIDEO,
  PLACEHOLDER,
  ABSTRACT,
  AUTHOR,
  TAGS,
  SECTION,
  SOURCE,
  QUOTE,
  MENTION,
  BLOCK_QUOTE,
  LINK,
  HORIZONTAL_RULE,
  TWEET_THREAD,
  LIVE_STREAM,
  EMBEDDED_TWEET,
  EMBEDDED_VIDEO,
  PRIVACY,
  OVERLINE,
  FACT,
  CAMERA,
  PACKAGE,
  VOICE_OVER,
  LIVE,
  FULLSCREEN_GRAPHICS,
  DVE,
  JINGLE,
  TELEPHONE,
  ADLIB,
  BREAK,
  OVERLAY_GRAPHICS,
  TEXT_TELE_PROMPTER,
  AUDIO,
  ACCESSORY,
} = elementTypes;

const elementComponents = {
  [PARAGRAPH]: Paragraph,
  [HORIZONTAL_RULE]: HorizontalRule,
  [LINK]: Link,
  [MENTION]: Mention,
  [TWEET_THREAD]: TweetThread,
  [TITLE]: Title,
  [DESCRIPTION]: Description,
  [HEADING_ONE]: Heading,
  [HEADING_TWO]: Heading,
  [HEADING_THREE]: Heading,
  [HEADING_FOUR]: Heading,
  [ORDERED_LIST]: List,
  [UNORDERED_LIST]: List,
  [LIST_ITEM]: ListItem,
  [BLOCK_QUOTE]: BlockQuote,
  [IMAGE]: Image,
  [GIF]: Gif,
  [VIDEO]: Video,
  [PLACEHOLDER]: Placeholder,
  [ABSTRACT]: Description,
  [AUTHOR]: Author,
  [TAGS]: Tags,
  [SECTION]: Section,
  [SOURCE]: Source,
  [QUOTE]: Quote,
  [LIVE_STREAM]: LiveStream,
  [EMBEDDED_TWEET]: EmbeddedTweet,
  [EMBEDDED_VIDEO]: EmbeddedVideo,
  [PRIVACY]: Privacy,
  [OVERLINE]: Overline,
  [FACT]: Fact,
  [CAMERA]: PrimaryAutomation,
  [PACKAGE]: PrimaryAutomation,
  [VOICE_OVER]: PrimaryAutomation,
  [LIVE]: PrimaryAutomation,
  [FULLSCREEN_GRAPHICS]: PrimaryAutomation,
  [DVE]: PrimaryAutomation,
  [JINGLE]: PrimaryAutomation,
  [TELEPHONE]: PrimaryAutomation,
  [ADLIB]: PrimaryAutomation,
  [BREAK]: PrimaryAutomation,
  [OVERLAY_GRAPHICS]: SecondaryAutomation,
  [TEXT_TELE_PROMPTER]: SecondaryAutomation,
  [AUDIO]: SecondaryAutomation,
  [ACCESSORY]: SecondaryAutomation,
};

const elementComponentMap = new Map(Object.entries(elementComponents));

export default elementComponentMap;
