import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';

/**
 * Toggles the specified mark
 *
 * @param editor SlateJS editor instance
 * @param type Type for the mark to toggle
 * @param isActive Specifies if the given mark type is active or not
 * @param value Value for the mark
 * @returns SlateJS editor instance
 */

const toggleMark = (
  editor: ReactEditor,
  type: string,
  isActive = false,
  value: boolean | string = true,
) => {
  const { removeMark, addMark } = Editor;

  if (isActive) removeMark(editor, type);
  else addMark(editor, type, value);
};

export default toggleMark;
