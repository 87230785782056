import { memo, useMemo } from 'react';
import styled from '@emotion/styled';

import { getSubtitleString, metadataType } from './utils';

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 176px);
  padding-left: 4px;
`;

const TemplateVariantText = styled('p')`
  ${(props) => props.theme.typography.listItemLabelMedium}
  margin:0;
`;

const SubtitleText = styled('p')`
  ${(props) => props.theme.typography.caption};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = ({
  templateVariant,
  meta,
  asset,
}: {
  templateVariant: string;
  meta: unknown[];
  asset: Record<string, never>;
}) => {
  const subtitle = useMemo(
    () => getSubtitleString(meta as metadataType, asset),
    [meta, asset],
  );
  return (
    <TitleWrapper>
      <TemplateVariantText>{templateVariant}</TemplateVariantText>
      <SubtitleText>{subtitle}</SubtitleText>
    </TitleWrapper>
  );
};

export default memo(Title);
