import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  padding: 8px;
`;

export const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SelectWrapper = styled('div')`
  width: 105px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  padding-left: 4px;
  ::after {
    position: absolute;
    right: 0;
    top: 4px;
    content: '';
    width: 1px;
    height: calc(100% - 8px);
    background-color: ${(props) => props.theme.colors.dividerLight};
  }
`;
