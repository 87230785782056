import { memo, useMemo } from 'react';
import { ReactComponent as TagIcon } from 'features/editor/assets/icons/Tags_off.svg';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import stringifyListWithValue from '../../utils/stringifyListWithValue';
import Box, { CollapsedLabel, RootWrapper, Title } from '../box';
import getContent from './utils/getContent';

const Tags = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.TAGS, children: [] },
}: RenderElementProps) => {
  const { data } = element;

  /** For backward compatibiy */
  const content = useMemo(
    () => getContent(data?.content as string),
    [data?.content],
  );

  const tags = stringifyListWithValue(content as Record<string, string>[]);

  return (
    <div {...attributes}>
      {children}
      <Box Icon={TagIcon}>
        <RootWrapper>
          <CollapsedLabel>Tags</CollapsedLabel>
          {tags ? <Title>{tags}</Title> : <Title italic>No content...</Title>}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(Tags);
