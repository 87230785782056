/* eslint-disable no-param-reassign */
import { ReactEditor } from 'slate-react';

import inlineTypes from '../../constants/types/inlineTypes';

/**
 * Wraps editor with overriden inline plugin functionalites
 *
 * @param editor SlateJS editor instance
 * @returns SlateJS editor instance
 */

const withInline = (editor: ReactEditor) => {
  const { isInline } = editor;

  editor.isInline = (element) =>
    Object.values(inlineTypes).includes(element.type) || isInline(element);

  return editor;
};

export default withInline;
