import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import DinaLogoSrc from 'assets/images/dina-logo.png';
import RestrictedSrc from 'assets/images/restricted.png';
import Loader from 'components/loader';

const ErrorText = styled('p')`
  ${(props) => props.theme.typography.h6};
  color: ${(props) => props.theme.colors.highEmphasis};
  margin: 0;
  margin-top: 16px;
`;

const ErrorReason = styled('p')`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.colors.highEmphasis};
  text-align: center;
  margin: 0;
  margin-top: 16px;
  padding: 0px 8px;
`;

const ErrorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 400px;
`;

const RootWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

const Footer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
`;

const Logo = styled('img')`
  margin-bottom: 12px;
`;

const FooterText = styled('p')`
  ${(props) => props.theme.typography.captionSmall};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  margin: 0;
  text-align: center;
`;

const AccessDeniedPage = () => {
  const [showAccessDenied, setShowAccessDenied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAccessDenied(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (!showAccessDenied)
    return (
      <RootWrapper>
        <Loader />
      </RootWrapper>
    );

  return (
    <RootWrapper>
      <ErrorWrapper>
        <img alt="restricted" src={RestrictedSrc} />
        <ErrorText>We are sorry</ErrorText>
        <ErrorReason>
          The content you are looking for is restricted, and only available from
          within the Dina Mobile app.
        </ErrorReason>
      </ErrorWrapper>
      <Footer>
        <Logo alt="dina-logo" src={DinaLogoSrc} />
        <FooterText>DiNA by 7Mountains</FooterText>
        <FooterText>Copyright © 2018–2022, 7Mountains AS</FooterText>
        <FooterText>All rights reserved.</FooterText>
      </Footer>
    </RootWrapper>
  );
};

export default AccessDeniedPage;
