import { CustomData } from 'features/editor/viewTypes';
import checkIfArray from 'utils/checkIfArray';

import { inTimingTypes, outTimingTypes } from './timingTypes';

const { MANUAL_IN } = inTimingTypes;
const { MANUAL_OUT } = outTimingTypes;

const getInitialData = ({
  templateType,
  templateVariant = 'VARIANT',
  metaData = [],
  assets = [],
  inTiming = MANUAL_IN,
  startTime = 0,
  outTiming = MANUAL_OUT,
  ...rest
}: CustomData) => ({
  templateType,
  templateVariant,
  inTiming,
  startTime,
  outTiming,
  metaData: checkIfArray(metaData),
  assets: checkIfArray(assets),
  ...rest,
});

export default getInitialData;
