import { memo } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as EmbeddedVideoSVG } from 'features/editor/assets/icons/embeddedVideo.svg';
import { elementTypes } from 'features/editor/constants/types';
import { RenderElementProps } from 'slate-react';

import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const EmbeddedVideoIcon = styled(EmbeddedVideoSVG)`
  path {
    fill: ${(props) => props.theme.colors.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

const EmbeddedVideo = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.EMBEDDED_VIDEO, children: [] },
}: RenderElementProps) => {
  const { url } = element.data ?? {};
  return (
    <div {...attributes}>
      {children}
      <Box type="media" Icon={EmbeddedVideoIcon}>
        <RootWrapper>
          <CollapsedLabel>Embedded Video</CollapsedLabel>
          {url ? (
            <Title breakWord>{url}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(EmbeddedVideo);
