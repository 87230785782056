import { memo } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';

const StyledLink = styled('a')`
  color: ${(props) => props.theme.colors.onSelected};
`;

const Link = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.LINK, children: [] },
}: RenderElementProps) => {
  const { data } = element;
  return (
    <StyledLink href={data?.href} {...attributes}>
      {children}
    </StyledLink>
  );
};

export default memo(Link);
