import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import theme from 'theme';

import { outTimingTypes } from '../../../../utils/timingTypes';
import { borderColor, edgeColor } from '../../utils/styleUtils';

const { ITEM_OUT, INSTANCE_OUT } = outTimingTypes;

const commonStyle = css`
  position: absolute;
  left: 1px;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 0.5px;
`;

const instanceOutStyle = css`
  ${commonStyle};
  background: rgba(15, 20, 26, 0.7);
`;

const itemOutStyle = css`
  ${commonStyle};
  border: 1px solid ${theme.colors.blackMediumEmphasis};
`;

interface RightEdgeProps {
  isManual: boolean;
  isGraphic: boolean;
  isSelected: boolean;
}

export const RightEdge = styled('div')<RightEdgeProps>`
  width: 8px;
  height: 100%;
  background-color: ${(props) =>
    props.isManual ? 'transparent' : edgeColor(props.isGraphic)};
  border-bottom-right-radius: 4px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-top-right-radius: 4px;
    border-top: ${borderColor};
    border-right: ${borderColor};
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-bottom-right-radius: 4px;
    border-bottom: ${borderColor};
    border-right: ${borderColor};
  }
`;

interface MiddleEdgeProps {
  outTimingType: string;
}

export const MiddleEdge = styled('div')<MiddleEdgeProps>`
  ${(props) => {
    if (props.outTimingType === ITEM_OUT) return itemOutStyle;
    if (props.outTimingType === INSTANCE_OUT) return instanceOutStyle;
    return null;
  }}
`;

export const RightEdgeWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;
