import { ReactComponent as AdlibIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_AdLib.svg';
import { ReactComponent as BreakIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_Break.svg';
import { ReactComponent as CameraIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_Camera.svg';
import { ReactComponent as DveIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_DVE.svg';
import { ReactComponent as FullscreenGraphicIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_Graphic.svg';
import { ReactComponent as JingleIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_Jingle.svg';
import { ReactComponent as LiveIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_Live.svg';
import { ReactComponent as TelephoneIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_Phone.svg';
import { ReactComponent as PackageIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_VideoClip.svg';
import { ReactComponent as VoiceOverIcon } from 'features/editor/assets/icons/primaryAutomation/Primary_Inline_VoiceOver.svg';
import { elementTypes } from 'features/editor/constants/types';

const {
  CAMERA,
  PACKAGE,
  VOICE_OVER,
  LIVE,
  FULLSCREEN_GRAPHICS,
  DVE,
  JINGLE,
  TELEPHONE,
  ADLIB,
  BREAK,
} = elementTypes;

const iconComponents = {
  [CAMERA]: CameraIcon,
  [PACKAGE]: PackageIcon,
  [VOICE_OVER]: VoiceOverIcon,
  [FULLSCREEN_GRAPHICS]: FullscreenGraphicIcon,
  [LIVE]: LiveIcon,
  [DVE]: DveIcon,
  [JINGLE]: JingleIcon,
  [BREAK]: BreakIcon,
  [TELEPHONE]: TelephoneIcon,
  [ADLIB]: AdlibIcon,
};

export default iconComponents;
