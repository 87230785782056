import styled from '@emotion/styled';

type LoaderProps = {
  width?: number;
  height?: number;
};

const StyledLoader = styled('div')<LoaderProps>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.width}px`};
  ::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-top: ${(props) => `3px solid ${props.theme.colors.highEmphasis}`};
    border-right: 3px solid transparent;
    animation: spinner 0.8s linear infinite;
  }
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ width = 40, height = 40 }: LoaderProps) => (
  <StyledLoader width={width} height={height} />
);

export default Loader;
