import { memo } from 'react';
import { ReactComponent as AuthorIcon } from 'features/editor/assets/icons/user.svg';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const normalizeArrayIntoString = (list: Record<string, string>[] = []) =>
  list.reduce((acc, current) => `${acc}${acc ? ', ' : ''}${current.value}`, '');

const Author = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.AUTHOR, children: [] },
}: RenderElementProps) => {
  const { data } = element;
  const content = data?.content || [];

  const authors = normalizeArrayIntoString(content as Record<string, string>[]);

  return (
    <div {...attributes}>
      {children}
      <Box Icon={AuthorIcon}>
        <RootWrapper>
          <CollapsedLabel>Author(s)</CollapsedLabel>
          {authors ? (
            <Title>{authors}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(Author);
