import { ReactComponent as AudioIcon } from 'features/editor/assets/icons/secondaryAutomation/Secondary_Audio_Small.svg';
import { ReactComponent as AccessoriesIcon } from 'features/editor/assets/icons/secondaryAutomation/secondary_code_small.svg';
import { ReactComponent as GraphicsIcon } from 'features/editor/assets/icons/secondaryAutomation/secondary_graphic.svg';
import { elementTypes } from 'features/editor/constants/types';

const { ACCESSORY, AUDIO, OVERLAY_GRAPHICS } = elementTypes;

const iconComponents = {
  [ACCESSORY]: AccessoriesIcon,
  [AUDIO]: AudioIcon,
  [OVERLAY_GRAPHICS]: GraphicsIcon,
};

export default iconComponents;
