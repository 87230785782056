import { Descendant } from 'slate';
import variants, { variantTypes } from 'utils/instanceVariants';

import { elementTypes } from '../constants/types';

export interface IEditorValue {
  document: Descendant[];
  version: string;
}

const version = '0.1.0';

export const initialValue: IEditorValue = {
  document: [
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
  version,
};

const cmsBlockInitialValue: IEditorValue = {
  version,
  document: [
    {
      type: 'title',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'description',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
  ],
};

const youtubeInitialValue: IEditorValue = {
  version,
  document: [
    {
      type: 'title',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'description',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'video',
      data: {
        showThumbnail: true,
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        content: [],
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'privacy',
      data: {
        content: 'public',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const facebookInitialValue: IEditorValue = {
  document: [
    {
      type: 'description',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
  ],
  version,
};

const getInitialValue = (
  variant: variantTypes,
  isBlockLayout: boolean,
): IEditorValue => {
  if (variant === variants.YOUTUBE) return youtubeInitialValue;
  if (variant === variants.FACEBOOK) return facebookInitialValue;
  if (variant === variants.CMS && isBlockLayout) return cmsBlockInitialValue;
  return initialValue;
};

export default getInitialValue;
