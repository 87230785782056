import React, { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as EffectIcon } from 'features/editor/assets/icons/effect.svg';
import { ReactComponent as MixIcon } from 'features/editor/assets/icons/mix.svg';

const TransitionWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 96px;
  padding-left: 4px;
  ::after {
    content: '';
    width: 1px;
    height: calc(100% - 8px);
    background-color: ${(props) => props.theme.colors.dividerLight};
  }
`;

const TransitionText = styled('p')`
  ${(props) => props.theme.typography.listItemLabel};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  margin: 0;
  width: calc(100% - 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const transitionIcons: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  EFFECT: EffectIcon,
  MIX: MixIcon,
};

interface TransitionProps {
  transitionValue: {
    type: string;
    value: string;
    name?: string;
  };
}

const Transition = ({ transitionValue }: TransitionProps) => {
  const TransitionIcon = useMemo(
    () => transitionIcons[transitionValue.type],
    [transitionValue.type],
  );

  return (
    <TransitionWrapper>
      <TransitionIcon />
      <TransitionText>{transitionValue.name || ''}</TransitionText>
    </TransitionWrapper>
  );
};

export default memo(Transition);
