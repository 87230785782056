import styled from '@emotion/styled';

export const ThreadInfo = styled('div')`
  ${(props) => props.theme.typography.overline};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  padding-top: 8px;
`;

export const ThreadHeader = styled('div')`
  display: flex;
  margin-left: 8px;
`;

export const InfoWrapper = styled('div')`
  flex: 1;
  margin: 0px 8px;
  border-top: ${(props) => `1px solid ${props.theme.colors.dividerLight}`};
`;

export const RootWrapper = styled('div')`
  margin: 0;
  padding-top: 16px;
`;
