import { memo } from 'react';
import { ReactComponent as PrivacyIcon } from 'features/editor/assets/icons/visibility.svg';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const Privacy = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.PRIVACY, children: [] },
}: RenderElementProps) => {
  const { data } = element;
  const content = (data?.content || 'public') as string;
  return (
    <div {...attributes}>
      {children}
      <Box Icon={PrivacyIcon}>
        <RootWrapper>
          <CollapsedLabel>Privacy</CollapsedLabel>
          {content ? (
            <Title>{capitalizeFirstLetter(content)}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(Privacy);
