import styled from '@emotion/styled';

export const List = styled('div')`
  background-color: ${(props) => props.theme.colors.surfaceCard};
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1200;
  width: calc(100% - 56px);
`;

interface ICircle {
  color: string;
}

export const Circle = styled('div')<ICircle>`
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-left: 16px;
  border: ${(props) =>
    props.color === 'transparent'
      ? `1px solid ${props.theme.colors.borderOutlined}`
      : `1px solid ${props.color}`};
`;

export const ListItemText = styled('p')`
  ${(props) => props.theme.typography.listItemLabel};
  color: ${(props) => props.theme.colors.highEmphasis};
  padding-left: 12px;
  padding-right: 8px;
`;

interface IListItemButton {
  selected: boolean;
}

export const ListItemButton = styled('div')<IListItemButton>`
  width: 100%;
  display: flex;
  align-items: center;

  ${(props) =>
    props.selected &&
    `
    background-color: ${props.theme.colors.onSelected};
    border-radius: 6px;
 `}
`;

export const ListItemSubtext = styled('p')`
  ${(props) => props.theme.typography.caption};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  margin-left: 8px;
`;
