import styled from '@emotion/styled';
import { ReactComponent as Send } from 'assets/icons/send.svg';

const Wrapper = styled('button')`
  width: 56px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  outline: none;
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.54 : 1)};
  background-color: ${({ theme }) => theme.colors.approved};
`;

const SendIcon = styled(Send)`
  width: 24px;
  height: 24px;
`;

interface DoneButtonProps {
  disabled?: boolean;
  handleDone?: () => void;
}

function DoneButton({
  disabled = false,
  handleDone = () => null,
}: DoneButtonProps) {
  return (
    <Wrapper onClick={handleDone} disabled={disabled}>
      <SendIcon />
    </Wrapper>
  );
}

export default DoneButton;
