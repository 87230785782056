/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { IConversation } from 'types/appTypes';

import Editor from '../../../editor/view';
import useCreateMessage from '../../hooks/useCreateMessage';
import getInitials from '../../utils/getInitials';
import ErrorOutline from './errorIcon';
import {
  Bubble,
  Container,
  IconMessage,
  InfoWrapper,
  LeftPointer,
  MessageRow,
  RightPointer,
  SenderImg,
  SenderWrapper,
  TextButton,
  VerticalDivider,
} from './styled';
import parseContent from './utils';

interface IMessageBubble {
  conversation?: IConversation;
  message: any;
  sender: any;
  isOwner: boolean;
}

function MessageBubble({
  conversation,
  message,
  sender,
  isOwner,
}: IMessageBubble) {
  const [createMessage] = useCreateMessage();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const timeDistance = formatDistanceToNow(new Date(message?.mCreatedAt), {
    addSuffix: true,
  });

  const handleDeleteMessage = async () => {
    try {
      await createMessage({
        userId: sender?.mId,
        mId: conversation?.mId,
        // @ts-expect-error mType for backward compatibility (remove when mobile is updated)
        convoType: conversation?.convoType ?? conversation?.mType,
        mRefId: message.mRefId,
        crudAction: 'DELETE',
      });
    } catch (e) {
      // console.log(e);
    }
  };

  const { parsedContent, parsedError } = parseContent(message.mContent ?? '');

  return (
    <Container isOwner={isOwner}>
      <MessageRow>
        {!isOwner && (
          <SenderWrapper>
            {sender?.mAvatarUrl ? (
              <SenderImg src={sender.mAvatarUrl} />
            ) : (
              getInitials(sender?.mTitle || 'Dina')
            )}
          </SenderWrapper>
        )}
        <Bubble isOwner={isOwner} isErrorMessage={!!parsedError}>
          {isOwner ? (
            <RightPointer isErrorMessage={!!parsedError} />
          ) : (
            <LeftPointer isErrorMessage={!!parsedError} />
          )}
          {parsedError ? (
            <IconMessage>
              <ErrorOutline />
              {parsedError}
            </IconMessage>
          ) : (
            <Editor
              content={parsedContent}
              variant="linear"
              fullHeight={false}
              readOnly
            />
          )}
        </Bubble>
      </MessageRow>
      <InfoWrapper isOwner={isOwner}>
        {!isOwner && (
          <>
            {sender?.mTitle}
            <VerticalDivider />
          </>
        )}
        {timeDistance}
        {isOwner && (
          <>
            <VerticalDivider />
            {showDeleteConfirm ? (
              <>
                <TextButton onClick={() => setShowDeleteConfirm(false)}>
                  Cancel
                </TextButton>
                <TextButton
                  confirm
                  onClick={() => {
                    handleDeleteMessage();
                    setShowDeleteConfirm(false);
                  }}
                >
                  Confirm Delete
                </TextButton>
              </>
            ) : (
              <TextButton onClick={() => setShowDeleteConfirm(true)}>
                Delete
              </TextButton>
            )}
          </>
        )}
      </InfoWrapper>
    </Container>
  );
}

export default MessageBubble;
