import styled from '@emotion/styled';
import {
  List as MuiList,
  MenuItem as MuiMenuItem,
  Paper,
  Typography,
} from '@mui/material';

import { Position } from '../../hooks/useCombobox';

interface ListWrapperProps {
  position: Position<string>;
}

export const ListWrapper = styled(Paper)<ListWrapperProps>`
  position: absolute;
  ${({ position }) =>
    position.bottom
      ? {
          bottom: position.bottom,
          left: position.left,
        }
      : {
          top: position.top,
          left: position.left,
        }};
  z-index: 2000;
  background-color: ${({ theme }) => theme.colors.surfaceCard};
`;

export const List = styled(MuiList)`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${({ theme }) => theme.colors.highEmphasis};
  padding: 4px 16px 4px 8px;
  width: 100%;
  justify-content: flex-start;
  &.Mui-selected {
    background-color: ${({ theme }) => theme.colors.borderResting};
  }
`;

export const Title = styled(Typography)`
  margin-left: 8px;
`;

export const Avatar = styled('img')`
  height: 24px;
  width: 24px;
  border: 1px solid ${({ theme }) => theme.colors.borderOutlined};
  border-radius: 50%;
  object-fit: cover;
`;
