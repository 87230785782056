import { memo, MouseEventHandler } from 'react';

import { BaseWrapper, ButtonWrapper } from './styled';

interface IButtonBase {
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isActive: boolean;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
}

const ButtonBase = ({
  IconComponent = () => null,
  isActive = false,
  onMouseDown = () => {},
}: IButtonBase) => {
  return (
    <BaseWrapper onMouseDown={onMouseDown as () => void}>
      <ButtonWrapper isActive={isActive}>
        <IconComponent />
      </ButtonWrapper>
    </BaseWrapper>
  );
};

export default memo(ButtonBase);
