import { memo } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ReactComponent as HourglassSvg } from 'features/editor/assets/icons/hourglass.svg';
import { ReactComponent as PlayCircleSvg } from 'features/editor/assets/icons/Play_WithCircleBackground.svg';
import TileSrc from 'features/editor/assets/images/Tile_Placholder.png';
import { elementTypes } from 'features/editor/constants/types';
import GET_SIGNED_URL from 'features/editor/graphql/query/getSignedUrl';

const { VIDEO, PLACEHOLDER } = elementTypes;

export const Thumbnail = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const EmptyThumbnail = styled('div')`
  background-image: ${`url(${TileSrc})`};
  background-size: 25%;
  width: 100%;
  height: 100%;
`;

export const ThumbnailWrapper = styled('div')`
  height: 56px;
  width: 56px;
  position: relative;
  border-right: ${(props) => `1px solid ${props.theme.colors.dividerLight}`};
`;

export const Info = styled('span')`
  ${(props) => props.theme.typography.caption};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  background-color: ${(props) => props.theme.colors.blackInactive};
  padding: 2px;
  border-radius: 2px;
  position: absolute;
  bottom: 2px;
  right: 2px;
`;

export const PlayIcon = styled(PlayCircleSvg)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const HourglassIcon = styled(HourglassSvg)`
  path {
    fill: ${(props) => props.theme.colors.statusWarning};
    fill-opacity: 1;
  }
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

interface IThumbnailView {
  thumbnailKey?: string;
  extension?: string;
  variant?: string;
  thumbnailFor?: string;
}

const ThumbnailView = ({
  variant,
  extension,
  thumbnailKey,
  thumbnailFor,
}: IThumbnailView) => {
  const { data } = useQuery(GET_SIGNED_URL, {
    variables: {
      input: {
        key: thumbnailKey,
      },
    },
    skip: !thumbnailKey,
  });

  const hasThumb = data?.getSignedUrl;

  const showPlayIcon =
    variant === VIDEO ||
    (hasThumb && variant === PLACEHOLDER && thumbnailFor === VIDEO);

  return (
    <ThumbnailWrapper>
      {hasThumb ? <Thumbnail src={data?.getSignedUrl} /> : <EmptyThumbnail />}
      {!hasThumb && variant === PLACEHOLDER && <HourglassIcon />}
      {showPlayIcon && <PlayIcon />}
      {extension && <Info>{extension}</Info>}
    </ThumbnailWrapper>
  );
};

export default memo(ThumbnailView);
