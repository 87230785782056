import styled from '@emotion/styled';

// eslint-disable-next-line import/prefer-default-export
export const MessageWrapper = styled('div')`
  color: ${(props) => props.theme.colors.highEmphasis};
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  overflow-y: auto;
  width: 100%;
`;
