const textColors = [
  { value: '#ffffff', title: 'Script', subtitle: 'To prompter, to be spoken' },
  {
    value: '#74db63',
    title: 'Presenter instructions',
    subtitle: 'To prompter',
  },
  {
    value: '#ef5757',
    title: 'Ignore text for prompting ',
    subtitle: 'Not visible on prompter',
  },
  {
    value: 'transparent',
    title: 'Reset',
    subtitle: 'Reset to default',
  },
];

export default textColors;
