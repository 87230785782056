/* eslint-disable no-param-reassign */
import voidTypes from 'features/editor/constants/types/voidTypes';
import { ReactEditor } from 'slate-react';

const voidTypeValues = Object.values(voidTypes);

/**
 * Wraps editor with overridden void plugin functionalites
 *
 * @param editor SlateJS editor instance
 * @returns SlateJS editor instance
 */

const withVoid = (editor: ReactEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) =>
    voidTypeValues.includes(element.type) || isVoid(element);

  return editor;
};

export default withVoid;
