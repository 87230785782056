/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';

interface IInputWrapper {
  focused?: boolean;
}

export const InputWrapper = styled('div')<IInputWrapper>`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.highEmphasis};
  border-radius: 8px;
  border: ${({ focused, theme }) =>
    `0.5px solid ${
      focused ? theme.colors.onFocus : theme.colors.borderOutlined
    }`};
  overflow: hidden;
`;
