import { useQuery } from '@apollo/client';
import Loader from 'components/loader';
import { IInstanceProperties } from 'types/appTypes';
import variants from 'utils/instanceVariants';

import GET_PLATFORM from './graphql/query/getPlatform';
import useFetchContent from './hooks/useFetchContent';
import { Backdrop, ErrorText } from './styled';
import EditorView from './view';

function EditorContainer({ instance }: { instance: IInstanceProperties }) {
  const [content, loading, error] = useFetchContent(instance.mContentKey);
  const { data: platformData, loading: platformLoading } = useQuery(
    GET_PLATFORM,
    {
      variables: {
        input: {
          mId: 'platform',
          mRefId: instance.platform,
        },
      },
      skip: instance.platform !== variants.CMS,
    },
  );

  if (error) return <ErrorText>{error.message}</ErrorText>;
  if (loading || platformLoading)
    return (
      <Backdrop>
        <Loader />
      </Backdrop>
    );

  return (
    <EditorView
      content={content}
      variant={instance.platform}
      platformProperties={platformData?.getMember?.mProperties}
    />
  );
}

export default EditorContainer;
