import { css } from '@emotion/react';
import styled from '@emotion/styled';

/** Used By Box itself */

export const BoxWrapper = styled('div')`
  display: flex;
  background-color: ${(props) => props.theme.colors.surfaceCard};
  box-shadow: ${(props) => `
   0 0 1px ${props.theme.colors.boxShadow}, 0 2px 2px ${props.theme.colors.boxShadow}
  `};
  border-radius: 8px;
  overflow: hidden;
  margin: 8px;
`;

interface IconWrapperProps {
  type?: 'media' | 'error' | 'cover';
}
export const IconWrapper = styled('div')<IconWrapperProps>`
  min-width: 32px;
  max-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ type, theme }) => {
    if (type === 'media') return theme.colors.timelineVideoClip;
    if (type === 'cover') return theme.colors.statusApproved;
    return theme.colors.buttonBackgroundHighEmphasis;
  }};
`;

/** Used by Component that utilizes Box */

export const RootWrapper = styled('div')`
  padding: 8px;
`;

export const Label = styled('p')`
  ${(props) => props.theme.typography.h7};
  color: ${(props) => props.theme.colors.highEmphasis};
  margin: 0;
`;

export const CollapsedLabel = styled('p')`
  ${(props) => props.theme.typography.overline};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  margin: 0;
  margin-bottom: 2px;
`;

interface TitleProps {
  italic?: boolean;
  truncate?: boolean;
  noOfLines?: number;
  breakWord?: boolean;
}

const truncateStyles = (props: TitleProps) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${props.noOfLines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Title = styled('p')<TitleProps>`
  margin: 0;
  ${(props) => props.theme.typography.listItemLabel};
  color: ${(props) => props.theme.colors.highEmphasis};
  font-style: ${(props) => (props.italic ? 'italic' : 'inherit')};
  ${(props) => props.truncate && truncateStyles};
  word-break: ${(props) => props.breakWord && 'break-all'};
`;

export const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleWrapper = styled('div')`
  flex: 1;
  margin-left: 8px;
  margin-right: 16px;
`;

export const Description = styled('p')`
  ${(props) => props.theme.typography.caption};
  color: ${(props) => props.theme.colors.mediumEmphasis};
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
