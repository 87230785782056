import { memo, useMemo } from 'react';
import { ReactComponent as TwitterIcon } from 'features/editor/assets/icons/publishingPoints/twitter.svg';
import { elementTypes } from 'features/editor/constants/types';
import { ReactEditor, useSlateStatic } from 'slate-react';

import { InfoWrapper, RootWrapper, ThreadHeader, ThreadInfo } from './styled';

const TweetThread = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.TWEET_THREAD, children: [] },
}) => {
  const editor = useSlateStatic();
  const [elementIndex] = useMemo(
    () => ReactEditor.findPath(editor, element),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const showThreadHeader: boolean =
    useMemo(
      () =>
        editor.children.reduce(
          (acc, current) =>
            current.type === elementTypes.TWEET_THREAD ? acc + 1 : acc,
          0,
        ),
      [editor],
    ) > 1;

  return (
    <RootWrapper {...attributes}>
      {showThreadHeader && (
        <ThreadHeader contentEditable={false}>
          <TwitterIcon />
          <InfoWrapper>
            <ThreadInfo>{`Thread Item #${elementIndex + 1}`}</ThreadInfo>
          </InfoWrapper>
        </ThreadHeader>
      )}
      <div>{children}</div>
    </RootWrapper>
  );
};

export default memo(TweetThread);
