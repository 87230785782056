import styled from '@emotion/styled';

export const TitleWrapper = styled('div')`
  width: calc(100% - 140px);
  padding-left: 8px;
  margin: 10px 0;
`;

export const TitleText = styled('p')`
  ${(props) => props.theme.typography.caption};
  margin: 0;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Label = styled('p')`
  ${(props) => props.theme.typography.caption};
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.mediumEmphasis};
  margin: 0;
  padding-bottom: 2px;
`;
