import { gql } from '@apollo/client';

export default gql`
  query GetPlatform($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mProperties {
        __typename
        ... on PlatformType {
          platformIcon
          platformAccount
          platformStructure {
            id
            name
            variant
            sections {
              blocks
              id
              name
            }
          }
        }
      }
    }
  }
`;
