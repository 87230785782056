import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IEditorWrapper {
  fullHeight: boolean;
}
export const EditorWrapper = styled('div')<IEditorWrapper>`
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100vh;
    `}
`;

interface IEditableWrapper {
  messageVariant: boolean;
}

export const EditableWrapper = styled('div')<IEditableWrapper>`
  padding: 8px;
  color: ${(props) => props.theme.colors.highEmphasis};
  font-size: 16px;
  height: 100%;
  overflow-y: auto;
  ${({ messageVariant }) =>
    messageVariant &&
    css`
      min-height: 40px;
      max-height: 160px;
    `}
`;

export const ErrorText = styled('p')`
  ${(props) => props.theme.typography.listItemLabel};
  color: ${(props) => props.theme.colors.highEmphasis};
  margin: 0;
  padding: 8px;
`;

export const Backdrop = styled('div')`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
`;
