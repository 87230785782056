import { memo } from 'react';
import styled from '@emotion/styled';
import { elementTypes, markTypes } from 'features/editor/constants/types';

import BlockButton from '../buttons/block';
import ColorButton from '../buttons/color';
import MarkButton from '../buttons/mark';

const Wrapper = styled('div')`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  padding-inline: 12px;
`;

const VerticalDivider = styled('div')`
  width: 1px;
  height: 32px;
  background-color: ${(props) => props.theme.colors.dividerLight};
  margin-inline: 4px;
`;

const HorizontalDivier = styled('div')`
  height: 1px;
  width: calc(100% - 16px);
  background-color: ${(props) => props.theme.colors.dividerLight};
`;

const OuterWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 8px;
`;

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;
const {
  HEADING_TWO,
  HEADING_THREE,
  HEADING_FOUR,
  UNORDERED_LIST,
  ORDERED_LIST,
  BLOCK_QUOTE,
} = elementTypes;

const GeneralToolbar = () => {
  return (
    <OuterWrapper>
      <Wrapper>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
        <VerticalDivider />
        <BlockButton type={HEADING_TWO} />
        <BlockButton type={HEADING_THREE} />
        <BlockButton type={HEADING_FOUR} />
        <ColorButton />
      </Wrapper>
      <HorizontalDivier />
      <Wrapper>
        <BlockButton type={ORDERED_LIST} />
        <BlockButton type={UNORDERED_LIST} />
        <VerticalDivider />
        <BlockButton type={BLOCK_QUOTE} />
      </Wrapper>
    </OuterWrapper>
  );
};

export default memo(GeneralToolbar);
