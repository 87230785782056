import { IEditorValue } from 'features/editor/utils';

const parseContent = (
  mContent?: string | null,
): {
  parsedContent?: IEditorValue;
  parsedError?: string;
} => {
  try {
    return {
      parsedContent: JSON.parse(mContent || '') as IEditorValue,
    };
  } catch (error) {
    return {
      parsedError: 'Invalid message content',
    };
  }
};

export default parseContent;
