import React, { memo } from 'react';

import { BoxWrapper, IconWrapper } from './styled';

type BoxProps = {
  /** Icon component to show on left */
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  /** children passed to box */
  children: React.ReactNode;
  /** whether the box is for Media or default */
  type?: 'media' | 'error' | 'cover';
};

const Box = ({ Icon, children = null, type }: BoxProps) => {
  return (
    <BoxWrapper contentEditable={false}>
      <IconWrapper type={type}>
        <Icon />
      </IconWrapper>
      {children}
    </BoxWrapper>
  );
};

export default memo(Box);
