import { memo } from 'react';
import styled from '@emotion/styled';
import getTimeString from 'utils/getTimeString';

import { outTimingTypes } from '../../utils/timingTypes';

const OutTimingText = styled('p')`
  ${(props) => props.theme.typography.listItemLabel}
  margin:0;
  color: ${(props) => props.theme.colors.mediumEmphasis};
`;

const { MANUAL_OUT, ITEM_OUT, INSTANCE_OUT, AUTO_OUT } = outTimingTypes;

const items = [
  { type: MANUAL_OUT, title: 'Manual Out' },
  { type: AUTO_OUT, title: 'Set Duration' },
  { type: ITEM_OUT, title: 'Item Out' },
  { type: INSTANCE_OUT, title: 'Instance Out' },
];

interface OutTimingProps {
  outTiming: string;
  duration: number;
}

const OutTiming = ({ outTiming, duration }: OutTimingProps) => {
  const showDuration = outTiming === AUTO_OUT;

  const convertedDuration = getTimeString(duration);

  const timingType = items.find((item) => item.type === outTiming);

  return (
    <OutTimingText>
      {showDuration ? convertedDuration : timingType?.title}
    </OutTimingText>
  );
};

export default memo(OutTiming);
