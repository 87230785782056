import { memo } from 'react';
import styled from '@emotion/styled';
import variants, { variantTypes } from 'utils/instanceVariants';

import GeneralToolbar from './components/generalToolbar';
import MessageToolbar from './components/messageToolbar';

interface ToolbarProps {
  readOnly: boolean;
  variant?: variantTypes;
  disableDone?: boolean;
  handleDone?: () => void;
}

export const StyledToolbar = styled('div')<ToolbarProps>`
  background-color: ${(props) => props.theme.colors.appBackgroundNavLevel1};
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
`;

const Toolbar = ({
  readOnly = false,
  variant,
  disableDone,
  handleDone,
}: ToolbarProps) => {
  return (
    <StyledToolbar readOnly={readOnly}>
      {variant === variants.MESSAGE ? (
        <MessageToolbar disableDone={disableDone} handleDone={handleDone} />
      ) : (
        <GeneralToolbar />
      )}
    </StyledToolbar>
  );
};

export default memo(Toolbar);
