import React, { memo } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected } from 'slate-react';

interface StyledParagraphProps {
  showHighlight: boolean;
}

const StyledParagraph = styled('p')<StyledParagraphProps>`
  z-index: 100;
  margin: 0px;
  padding: 8px;
  background: ${(props) =>
    props.showHighlight && props.theme.colors.blackHoverOverlay};
  border-radius: 4px;
`;

const Paragraph = ({ attributes, children, element }: RenderElementProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isSelected = useSelected(element);
  return (
    <StyledParagraph showHighlight={isSelected} {...attributes}>
      {children}
    </StyledParagraph>
  );
};
export default memo(Paragraph);
