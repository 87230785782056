import inlineTypes from './inlineTypes';
import voidTypes from './voidTypes';

const elementTypes = {
  PARAGRAPH: 'paragraph',
  HEADING_ONE: 'heading-one',
  HEADING_TWO: 'heading-two',
  HEADING_THREE: 'heading-three',
  HEADING_FOUR: 'heading-four',
  ORDERED_LIST: 'ordered-list',
  UNORDERED_LIST: 'unordered-list',
  BLOCK_QUOTE: 'block-quote',
  LIST_ITEM: 'list-item',
  TWEET_THREAD: 'tweet-thread',
  ...inlineTypes,
  ...voidTypes,
};

export default elementTypes;
