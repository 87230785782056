/* eslint-disable no-param-reassign */
import { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import GET_USERS from 'features/messageHub/graphql/getUsers';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { IUser } from 'types/appTypes';

import defaultThumbnail from '../../assets/images/defaultThumbnail.png';
import { elementTypes } from '../../constants/types';
import useCombobox, { Position } from '../../hooks/useCombobox';
import { Avatar, List, ListWrapper, MenuItem, Title } from './styled';

const beforeExp = /^@([\p{L}0-9_-]+)$/u;
const afterExp = /^(\s|$)/;

function Portal({ children }: { children: ReactNode }) {
  return createPortal(children, document.body);
}

const { select, insertNodes, move } = Transforms;

interface GetUserType {
  getMembersOftype: IUser[];
}

interface Props {
  user?: IUser;
}

function Suggestions({ user }: Props) {
  const editor = useSlate();
  const [search, setSearch] = useState('');

  const { data } = useQuery<GetUserType>(GET_USERS, {
    variables: {
      input: {
        mType: 'user',
      },
    },
    fetchPolicy: 'cache-only',
  });

  const filteredUsers = useMemo(
    () =>
      (data?.getMembersOftype || [])
        .filter(
          ({ mTitle, mId }) =>
            mId !== user?.mId &&
            mTitle.toLowerCase().startsWith(search.toLowerCase()),
        )
        .slice(0, 10)
        .sort((a, b) => a.mTitle.localeCompare(b.mTitle)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, data],
  );

  const insertMention = useCallback(
    (userData, targetNode) => {
      const mention = {
        type: elementTypes.MENTION,
        data: userData,
        children: [{ text: '' }],
      };

      const offsetDistance = (userData?.mTitle?.length || 0) + 1;
      select(editor, targetNode);
      insertNodes(editor, mention);
      move(editor, { unit: 'offset', distance: offsetDistance });
      ReactEditor.focus(editor);
    },
    [editor],
  );

  const { position, target, cursor } = useCombobox(
    filteredUsers,
    insertMention,
    setSearch,
    {
      beforeExp,
      afterExp,
    },
  );

  const showSuggestions = target && filteredUsers.length > 0;

  const onAvatarError: React.ReactEventHandler<HTMLImageElement> = (event) => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = defaultThumbnail;
  };

  return showSuggestions ? (
    <Portal>
      <ListWrapper position={position as Position<string>} elevation={12}>
        <List>
          {filteredUsers.map((fUser, index) => (
            <MenuItem
              dense
              selected={index === cursor}
              key={fUser.mId}
              onClick={() => insertMention(fUser, target)}
            >
              <Avatar
                alt="avatar"
                src={fUser.mAvatarUrl || defaultThumbnail}
                onError={onAvatarError}
              />
              <Title>{fUser.mTitle}</Title>
            </MenuItem>
          ))}
        </List>
      </ListWrapper>
    </Portal>
  ) : null;
}

export default memo(Suggestions);
