/* eslint-disable react/react-in-jsx-scope */
/** @jsxImportSource @emotion/react */
import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { RenderElementProps } from 'slate-react';

import listComponents from '../../constants/listComponents';
import { elementTypes } from '../../constants/types';

const listItemStyle = css`
  margin: 0;
  padding: 2px 0px;
`;

const ListItem = ({
  attributes,
  children = null,
  element = {
    type: elementTypes.LIST_ITEM,
    children: [],
  },
}: RenderElementProps) => {
  const ListComponent = useMemo(
    () => listComponents[element.type],
    [element.type],
  );

  return (
    <ListComponent css={listItemStyle} {...attributes}>
      {children}
    </ListComponent>
  );
};

export default memo(ListItem);
