import { memo } from 'react';
import { ReactComponent as EmbeddedTweetIcon } from 'features/editor/assets/icons/embeddedTweet.svg';
import { elementTypes } from 'features/editor/constants/types';
import { RenderElementProps } from 'slate-react';

import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const EmbeddedTweet = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.EMBEDDED_TWEET, children: [] },
}: RenderElementProps) => {
  const { tweetUrl } = element.data ?? {};
  return (
    <div {...attributes}>
      {children}
      <Box Icon={EmbeddedTweetIcon}>
        <RootWrapper>
          <CollapsedLabel>Embedded Tweet</CollapsedLabel>
          {tweetUrl ? (
            <Title breakWord>{tweetUrl}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(EmbeddedTweet);
