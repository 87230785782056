import { ReactComponent as BlockQuote } from 'features/editor/assets/icons/blocks_plain_quote_off.svg';
import { ReactComponent as HeadingTwo } from 'features/editor/assets/icons/toolbar/h2_off.svg';
import { ReactComponent as HeadingThree } from 'features/editor/assets/icons/toolbar/h3_off.svg';
import { ReactComponent as HeadingFour } from 'features/editor/assets/icons/toolbar/h4_off.svg';
import { ReactComponent as NumberedList } from 'features/editor/assets/icons/toolbar/listOrdered_off.svg';
import { ReactComponent as BulletedList } from 'features/editor/assets/icons/toolbar/listUnordered_off.svg';
import { elementTypes } from 'features/editor/constants/types';

const {
  HEADING_TWO,
  HEADING_THREE,
  HEADING_FOUR,
  UNORDERED_LIST,
  ORDERED_LIST,
  BLOCK_QUOTE,
} = elementTypes;

const iconComponents = {
  [HEADING_TWO]: HeadingTwo,
  [HEADING_THREE]: HeadingThree,
  [HEADING_FOUR]: HeadingFour,
  [UNORDERED_LIST]: BulletedList,
  [ORDERED_LIST]: NumberedList,
  [BLOCK_QUOTE]: BlockQuote,
};

export default iconComponents;
