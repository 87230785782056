import isList from 'features/editor/components/list/uitls/isList';
import { elementTypes } from 'features/editor/constants/types';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

const { unwrapNodes, setNodes, wrapNodes } = Transforms;

/**
 * Toggles the specified element
 *
 * @param editor SlateJS editor instance
 * @param type Type for the node to toggle
 * @param isActive Specifies if the given element type is active or not
 * @returns SlateJS editor instance
 */

const toggleBlock = (editor: ReactEditor, type: string, isActive = false) => {
  unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n),
    split: true,
  });

  const newProperties: Partial<SlateElement> = {
    // eslint-disable-next-line no-nested-ternary
    type: isActive
      ? elementTypes.PARAGRAPH
      : isList({ type })
      ? elementTypes.LIST_ITEM
      : type,
  };

  setNodes<SlateElement>(editor, newProperties, { mode: 'highest' });

  if (!isActive && isList({ type })) {
    const block = { type, children: [] };

    wrapNodes(editor, block);
  }
};

export default toggleBlock;
