import React, { memo } from 'react';
import { ReactComponent as QuoteIcon } from 'features/editor/assets/icons/blocks_plain_quote_off.svg';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const QuoteBlock = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.QUOTE, children: [] },
}: RenderElementProps) => {
  const { data } = element;

  const quote = data?.quote || '';

  return (
    <div {...attributes}>
      {children}
      <Box Icon={QuoteIcon}>
        <RootWrapper>
          <CollapsedLabel>Quote</CollapsedLabel>
          {quote ? <Title>{quote}</Title> : <Title italic>No content...</Title>}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(QuoteBlock);
