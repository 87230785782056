import { memo } from 'react';
import styled from '@emotion/styled';
import getTimeString from 'utils/getTimeString';

import { inTimingTypes } from '../../utils/timingTypes';

const InTimingText = styled('p')`
  ${(props) => props.theme.typography.listItemLabel}
  margin:0;
  color: ${(props) => props.theme.colors.mediumEmphasis};
`;

const { AUTO_IN, MANUAL_IN } = inTimingTypes;

const items = [
  { type: MANUAL_IN, title: 'Manual In' },
  { type: AUTO_IN, title: 'Set Start Time' },
];

interface InTimingProps {
  inTiming: string;
  startTime: number;
}

const InTiming = ({ inTiming, startTime }: InTimingProps) => {
  const showStartTime = inTiming === AUTO_IN;

  const convertedStartTime = getTimeString(startTime);

  const timingType = items.find((item) => item.type === inTiming);

  return (
    <InTimingText>
      {showStartTime ? convertedStartTime : timingType?.title}
    </InTimingText>
  );
};

export default memo(InTiming);
