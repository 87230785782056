const variants = {
  LINEAR: 'linear',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  CMS: 'cms',
  GENERAL: 'general',
  NOTES: 'notes',
  MESSAGE: 'message',
  DAILYNOTE: 'dailyNote',
} as const;

export type variantTypes = typeof variants[keyof typeof variants];

export default variants;
