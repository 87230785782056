import { memo } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as GifIcon } from 'features/editor/assets/icons/GIF_off.svg';
import getThumbnailKey from 'features/editor/utils/getThumbnailKey';
import { RenderElementProps } from 'slate-react';

import Box, { ContentWrapper, Title, TitleWrapper } from '../box';
import Thumbnail from '../image/components/thumbnail';

const StyledGifIcon = styled(GifIcon)`
  path {
    fill: ${(props) => props.theme.colors.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

const Gif = ({ attributes, element, children }: RenderElementProps) => {
  const { mId, mRefId, src, title: generatedTitle } = element.data ?? {};
  const key =
    src || (getThumbnailKey(mId as string, mRefId as string) as string);

  return (
    <div {...attributes}>
      {children}
      <Box type="media" Icon={StyledGifIcon}>
        <ContentWrapper>
          <Thumbnail thumbnailKey={key} />
          <TitleWrapper>
            {key ? (
              <Title>{generatedTitle}</Title>
            ) : (
              <Title italic>No GIF added yet...</Title>
            )}
          </TitleWrapper>
        </ContentWrapper>
      </Box>
    </div>
  );
};

export default memo(Gif);
