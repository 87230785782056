import React, { memo } from 'react';
import { ReactComponent as TitleIcon } from 'features/editor/assets/icons/h1_off.svg';
import { RenderElementProps } from 'slate-react';

import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const TitleView = ({ attributes, children, element }: RenderElementProps) => {
  const { data } = element;
  const content = data?.content || '';

  return (
    <div {...attributes}>
      {children}
      <Box Icon={TitleIcon}>
        <RootWrapper>
          <CollapsedLabel>Title</CollapsedLabel>
          {content ? (
            <Title>{content}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(TitleView);
