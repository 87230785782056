import React, { memo } from 'react';
import { ReactComponent as SourceIcon } from 'features/editor/assets/icons/source.svg';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import stringifyListWithValue from '../../utils/stringifyListWithValue';
import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const Source = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.SECTION, children: [] },
}: RenderElementProps) => {
  const { data } = element;

  const sources = stringifyListWithValue(
    (data?.content as Record<string, string>[]) || [],
  );

  return (
    <div {...attributes}>
      {children}
      <Box Icon={SourceIcon}>
        <RootWrapper>
          <CollapsedLabel>Source</CollapsedLabel>
          {sources ? (
            <Title>{sources}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(Source);
