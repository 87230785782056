/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/react-in-jsx-scope */
/** @jsxImportSource @emotion/react */
import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { RenderElementProps, useReadOnly, useSelected } from 'slate-react';
import theme from 'theme';

import headingComponents from '../../constants/headingComponents';
import { elementTypes } from '../../constants/types';

const headingStyle = css`
  margin: 0;
  padding: 8px;
  border-radius: 4px;
`;

const highlightStyle = css`
  background-color: ${theme.colors.blackHoverOverlay};
`;

const Heading = ({
  attributes,
  children = null,
  element = {
    type: elementTypes.HEADING_ONE,
    children: [],
  },
}: RenderElementProps) => {
  // @ts-ignore
  const isSelected = useSelected(element);
  const isReadOnly = useReadOnly();
  const showHighlight = isSelected && !isReadOnly;

  const MemoizedHeading = useMemo(
    () => headingComponents[element.type],
    [element.type],
  );

  return (
    <MemoizedHeading
      css={[headingStyle, showHighlight && highlightStyle]}
      {...attributes}
    >
      {children}
    </MemoizedHeading>
  );
};

export default memo(Heading);
