import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';

/**
 * Gets mark of specified type
 *
 * @param editor SlateJS editor instance
 * @param type Type for the mark
 * @returns Boolean
 */

const getMark = (editor: ReactEditor, type: string) => {
  const marks = Editor.marks(editor) as never;

  return marks && marks[type];
};

export default getMark;
