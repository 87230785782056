/* eslint-disable import/no-extraneous-dependencies */
import { memo, useCallback, useState } from 'react';
import { isEqual } from 'lodash';
import { BaseEditor, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
} from 'slate-react';
import { IUser } from 'types/appTypes';
import variants from 'utils/instanceVariants';

import Leaf from './components/leaf';
import Paragraph from './components/paragraph';
import Suggestions from './components/suggestions';
import Toolbar from './components/toolbar';
import elementComponents from './constants/elementComponents';
import layoutVariants from './constants/layoutVariants';
import { EditableWrapper, EditorWrapper } from './styled';
import { getInitialValues, withInline, withLink, withVoid } from './utils';
import { EditorProps, Element, Text } from './viewTypes';

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: Element;
    Text: Text;
  }
}

const shouldHidePlaceholder = () => {
  const agent = navigator.userAgent;
  const index = agent.indexOf('iPhone OS');
  if (index > -1) {
    return agent.slice(index + 10, index + 14).replace('_', '.') > '16.1';
  }
  return false;
};

const Editor = ({
  content,
  variant,
  platformProperties,
  fullHeight = true,
  readOnly = true,
  placeholder,
  user,
  onDone,
  enableMention,
}: EditorProps) => {
  const [editor] = useState(() =>
    withLink(withInline(withVoid(withHistory(withReact(createEditor()))))),
  );
  const resetSelection = useCallback(() => {
    const { deselect, blur } = ReactEditor;
    deselect(editor);
    blur(editor);
  }, [editor]);

  const isBlockLayout =
    platformProperties?.platformStructure?.variant === layoutVariants.BLOCKS;

  const { document: initialDocument, ...rest } =
    content ?? getInitialValues(variant, isBlockLayout);

  const renderElement = useCallback((props) => {
    const ElementComponent =
      (elementComponents.get(props.element.type) as React.MemoExoticComponent<
        ({ attributes, children, element }: RenderElementProps) => JSX.Element
      >) || Paragraph;

    return <ElementComponent {...props} />;
  }, []);

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const messageVariant = variant === variants.MESSAGE;

  const handleDone = useCallback(() => {
    if (onDone && !isEqual(editor.children, initialDocument)) {
      onDone({ document: editor.children, ...rest });
    }
    resetSelection();
    editor.children = initialDocument;
  }, [editor, initialDocument, onDone, resetSelection, rest]);

  return (
    <EditorWrapper fullHeight={fullHeight}>
      <Slate editor={editor} value={initialDocument}>
        {messageVariant && (
          <Toolbar
            readOnly={readOnly}
            variant={variant}
            handleDone={handleDone}
          />
        )}
        <EditableWrapper messageVariant={messageVariant}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={
              shouldHidePlaceholder()
                ? ''
                : placeholder ?? 'No content added...'
            }
            readOnly={readOnly}
          />
        </EditableWrapper>
        {enableMention && <Suggestions user={user as IUser} />}
      </Slate>
    </EditorWrapper>
  );
};

export default memo(Editor);
