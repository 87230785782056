import styled from '@emotion/styled/macro';

import { borderColor } from './utils/styleUtils';

interface MainWrapperProps {
  isSelected: boolean;
}

export const MainWrapper = styled('div')<MainWrapperProps>`
  width: 100%;
  min-height: 56px;
  border-radius: 4px;
  display: flex;
  position: relative;
  border: ${(props) =>
    props.isSelected && `1px solid ${props.theme.colors.onFocus}`};
`;

interface ChildWrapperProps {
  isSelected: boolean;
  isGraphic: boolean;
}

export const ChildWrapper = styled('div')<ChildWrapperProps>`
  width: calc(100% - 16px);
  margin: 0 8px;
  border-top: ${borderColor};
  border-bottom: ${borderColor};
  box-sizing: border-box;
`;
