import { ReactComponent as Bold } from 'features/editor/assets/icons/toolbar/bold_off.svg';
import { ReactComponent as Italic } from 'features/editor/assets/icons/toolbar/italic_off.svg';
import { ReactComponent as StrikeThrough } from 'features/editor/assets/icons/toolbar/strikethrough_off.svg';
import { ReactComponent as Underline } from 'features/editor/assets/icons/toolbar/underline_off.svg';
import { markTypes } from 'features/editor/constants/types';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const iconComponents: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [BOLD]: Bold,
  [ITALIC]: Italic,
  [UNDERLINE]: Underline,
  [STRIKE_THROUGH]: StrikeThrough,
};

export default iconComponents;
