import { memo } from 'react';
import styled from '@emotion/styled';
import { elementTypes, markTypes } from 'features/editor/constants/types';

import BlockButton from '../buttons/block';
import DoneButton from '../buttons/done';
import MarkButton from '../buttons/mark';

const OuterWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;
  gap: 6px;
`;

const Wrapper = styled('div')`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const VerticalDivider = styled('div')`
  width: 1px;
  height: 32px;
  margin-inline: 4px;
  background-color: ${(props) => props.theme.colors.dividerLight};
`;

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;
const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

interface IMessageToolbarProps {
  disableDone?: boolean;
  handleDone?: () => void;
}

const MessageToolbar = ({ disableDone, handleDone }: IMessageToolbarProps) => {
  return (
    <OuterWrapper>
      <Wrapper>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
        <VerticalDivider />
        <BlockButton type={ORDERED_LIST} />
        <BlockButton type={UNORDERED_LIST} />
      </Wrapper>
      <DoneButton disabled={disableDone} handleDone={handleDone} />
    </OuterWrapper>
  );
};

export default memo(MessageToolbar);
