import { primaryTypes, secondaryTypes } from './automationTypes';

const voidTypes = {
  HORIZONTAL_RULE: 'horizontal-rule',
  MENTION: 'mention',
  DESCRIPTION: 'description',
  TITLE: 'title',
  VIDEO: 'video',
  IMAGE: 'image',
  GIF: 'gif',
  PLACEHOLDER: 'placeholder',
  ABSTRACT: 'abstract',
  AUTHOR: 'author',
  TAGS: 'tags',
  SECTION: 'section',
  SOURCE: 'source',
  QUOTE: 'quote',
  LIVE_STREAM: 'live-stream',
  EMBEDDED_VIDEO: 'embedded-video',
  EMBEDDED_TWEET: 'embedded-tweet',
  PRIVACY: 'privacy',
  OVERLINE: 'overline',
  FACT: 'fact',
  ...primaryTypes,
  ...secondaryTypes,
};

export default voidTypes;
