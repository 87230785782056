import { memo, useCallback } from 'react';

import {
  Circle,
  List,
  ListItemButton,
  ListItemSubtext,
  ListItemText,
} from './styled';
import textColors from './utils/textColors';

type ColorListProps = {
  onColorSelection: (value: string) => void;
  markColor: string;
};

const ColorList = ({
  markColor,
  onColorSelection = () => {},
}: ColorListProps) => {
  const handleClick = useCallback((event, colorValue) => {
    event?.preventDefault();
    onColorSelection(colorValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List>
      {textColors.map((textColor) => (
        <ListItemButton
          key={textColor.value}
          onClick={(event) => handleClick(event, textColor.value)}
          selected={
            markColor !== 'transparent' && markColor === textColor.value
          }
        >
          <Circle color={textColor.value} />
          <ListItemText>{textColor.title}</ListItemText>
          <ListItemSubtext>{textColor.subtitle}</ListItemSubtext>
        </ListItemButton>
      ))}
    </List>
  );
};

export default memo(ColorList);
