import { memo } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';

export const Wrapper = styled('span')`
  background-color: ${(props) =>
    props.theme.colors.storyTimelineCurrentTimeIndicator};
  border-radius: 6px;
  padding: 2px 6px;
`;

const Mention = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.MENTION, children: [] },
}: RenderElementProps) => {
  const { data } = element;

  return (
    <Wrapper {...attributes}>
      <span>
        @{data?.mTitle}
        {children}
      </span>
    </Wrapper>
  );
};

export default memo(Mention);
