/* eslint-disable react/react-in-jsx-scope */
/** @jsxImportSource @emotion/react */
import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { RenderElementProps, useReadOnly, useSelected } from 'slate-react';
import theme from 'theme';

import listComponents from '../../constants/listComponents';
import { elementTypes } from '../../constants/types';

const listStyle = css`
  margin: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
`;

const highlightStyle = css`
  background-color: ${theme.colors.blackHoverOverlay};
`;

const List = ({
  attributes,
  children = null,
  element = {
    type: elementTypes.LIST_ITEM,
    children: [],
  },
}: RenderElementProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isSelected = useSelected(element);
  const isReadOnly = useReadOnly();
  const showHighlight = isSelected && !isReadOnly;

  const ListComponent = useMemo(
    () => listComponents[element.type],
    [element.type],
  );

  return (
    <ListComponent
      css={[listStyle, showHighlight && highlightStyle]}
      {...attributes}
    >
      {children}
    </ListComponent>
  );
};

export default memo(List);
