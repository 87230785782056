import React, { memo } from 'react';
import styled from '@emotion/styled';
import { RenderLeafProps } from 'slate-react';

interface StyledLeafProps {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikeThrough?: boolean;
}

const LeafWrapper = styled('span')<StyledLeafProps>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-decoration: ${({ underline, strikeThrough }) => {
    if (underline) return 'underline';
    if (strikeThrough) return 'line-through';
    return 'none';
  }};
  color: ${({ color }) => color || 'inherit'};
`;

const Leaf = ({
  attributes = { 'data-slate-leaf': true },
  children = null,
  leaf,
}: RenderLeafProps) => (
  <LeafWrapper {...leaf} {...attributes}>
    {children}
  </LeafWrapper>
);

export default memo(Leaf);
