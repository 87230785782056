/* eslint-disable no-param-reassign */
import { ReactEditor } from 'slate-react';
import isUrl from 'utils/isUrl';

import wrapLink from '../../components/link/utils/wrapLink';

/**
 * Wraps editor with overriden plugin functionalites
 *
 * @param editor SlateJS editor instance
 * @returns SlateJS editor instance
 */

const withLink = (editor: ReactEditor) => {
  const { insertData, insertText } = editor;

  editor.insertText = (text) => {
    if (text && isUrl(text)) wrapLink(editor, text);
    else insertText(text);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) wrapLink(editor, text);
    else insertData(data);
  };

  return editor;
};

export default withLink;
