import Loader from 'components/loader';
import useFetchContent from 'features/editor/hooks/useFetchContent';
import { Backdrop, ErrorText } from 'features/editor/styled';
import EditorView from 'features/editor/view';
import { IDailyNoteProperties } from 'types/appTypes';
import variants from 'utils/instanceVariants';

const View = ({ dailyNote }: { dailyNote: IDailyNoteProperties }) => {
  const [content, loading, error] = useFetchContent(dailyNote.mContentKey);

  if (error) return <ErrorText>{error.message}</ErrorText>;
  if (loading)
    return (
      <Backdrop>
        <Loader />
      </Backdrop>
    );

  return <EditorView content={content} variant={variants.DAILYNOTE} />;
};

export default View;
