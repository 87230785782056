/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useSubscription } from '@apollo/client';
import { IMessageProps } from 'types/appTypes';

import CONTENT_CHAT_SUB from '../../graphql/contentChatSub';
import CONVERSATION_SUB from '../../graphql/conversationSub';
import GET_MESSAGES_OF_CONVERSATION from '../../graphql/getConversations';
import GET_USERS from '../../graphql/getUsers';
import updateMessageInConversation from '../../utils/updateMessageInConversation';
import MessageBubble from '../messageBubble';
import { MessageWrapper } from './styled';

function Messages({ conversation, user }: Readonly<IMessageProps>) {
  const variables = {
    input: { mId: conversation?.mId },
    limit: 25,
    nextToken: undefined,
  };

  const { data: users } = useQuery(GET_USERS, {
    variables: {
      input: {
        mType: 'user',
      },
    },
    skip: !conversation?.mId,
    fetchPolicy: 'cache-and-network',
  });

  const { data, fetchMore } = useQuery(GET_MESSAGES_OF_CONVERSATION, {
    variables,
    skip: !conversation?.mId,
    fetchPolicy: 'cache-and-network',
  });

  const messages = data?.getMessagesOfConversation?.items || [];
  const hasMore = Boolean(data?.getMessagesOfConversation?.nextToken);

  const sortedMessages = [...messages].sort((a, b) =>
    a.mCreatedAt > b.mCreatedAt ? -1 : 1,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLoadMore = async () => {
    if (hasMore) {
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data.getMessagesOfConversation.nextToken,
        },
        updateQuery: (previousResult: any, { fetchMoreResult }) => ({
          getMessagesOfConversation: {
            items: [
              ...previousResult.getMessagesOfConversation.items,
              ...fetchMoreResult.getMessagesOfConversation.items,
            ],
            nextToken: fetchMoreResult.getMessagesOfConversation.nextToken,
            __typename: 'PaginatedMessageType',
          },
        }),
      });
    }
  };

  useSubscription(CONVERSATION_SUB, {
    variables: { mIdSubscribed: user?.mId },
    onSubscriptionData({ client, subscriptionData }) {
      const payload = subscriptionData?.data?.notifyConversationSubscription;
      if (conversation?.mId === payload.mId) {
        updateMessageInConversation(client, payload);
      }
    },
  });

  useSubscription(CONTENT_CHAT_SUB, {
    variables: { ids: JSON.stringify([conversation?.mId]) },
    onSubscriptionData({ client, subscriptionData }) {
      const payload = subscriptionData?.data?.updateContentChat;
      if (!payload || payload.mUpdatedById === user?.mId) return;
      if (conversation?.mId === payload.mId) {
        const crudAction = payload.mContent ? 'INSERT' : 'REMOVE';
        payload.message = JSON.stringify({
          mContent: payload.mContent,
          crudAction,
        });
        updateMessageInConversation(client, payload);
      }
    },
  });

  return (
    <MessageWrapper>
      {sortedMessages.map((message: any) => {
        const { mRefId, mCreatedById, mUpdatedById } = message;
        const isOwner = user?.mId === (mCreatedById || mUpdatedById);
        const sender = isOwner
          ? user
          : users?.getMembersOftype.find(
              (u: any) => u.mId === (mCreatedById || mUpdatedById),
            );
        return (
          <MessageBubble
            key={mRefId}
            conversation={conversation}
            message={message}
            sender={sender}
            isOwner={isOwner}
          />
        );
      })}
    </MessageWrapper>
  );
}

export default Messages;
