const primaryTypes = {
  CAMERA: 'camera',
  PACKAGE: 'package',
  VOICE_OVER: 'voiceOver',
  LIVE: 'live',
  FULLSCREEN_GRAPHICS: 'fullscreenGraphics',
  DVE: 'dve',
  JINGLE: 'jingle',
  TELEPHONE: 'telephone',
  ADLIB: 'adlib',
  BREAK: 'break',
};

const secondaryTypes = {
  OVERLAY_GRAPHICS: 'overlayGraphics',
  AUDIO: 'audio',
  TEXT_TELE_PROMPTER: 'textTelePrompter',
  ACCESSORY: 'accessory',
};

export { primaryTypes, secondaryTypes };
