/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import GET_MESSAGES_OF_CONVERSATION from '../graphql/getConversations';

const updateMessageInConversation = (proxy: any, payload: any) => {
  try {
    const { mId, message } = payload;
    const { mContent, crudAction } = JSON.parse(message);

    const variables = {
      input: { mId },
      limit: 25,
    };

    const { getMessagesOfConversation = {} } = proxy.readQuery({
      query: GET_MESSAGES_OF_CONVERSATION,
      variables,
    });

    const newMessageList = [];
    const { items = [] } = getMessagesOfConversation;

    switch (crudAction) {
      case 'REMOVE':
        newMessageList.push(
          ...items.filter(
            (item: { mRefId: any }) => item.mRefId !== payload.mRefId,
          ),
        );
        break;
      case 'INSERT':
        // eslint-disable-next-line no-underscore-dangle
        payload.__typename = 'MessageType';
        payload.mContent = mContent;
        if (items[0]?.mRefId === payload.mRefId) return;
        newMessageList.push(payload, ...items);
        break;
      case 'UPDATE':
        // eslint-disable-next-line no-case-declarations
        const itemIndex = items.findIndex(
          (item: { mRefId: any }) => item.mRefId === payload.mRefId,
        );
        if (itemIndex !== -1) items[itemIndex].mContent = mContent;
        newMessageList.push(...items);
        break;
      default:
        // unknown operation, just copy the current items;
        newMessageList.push(...items);
        break;
    }
    proxy.writeQuery({
      query: GET_MESSAGES_OF_CONVERSATION,
      variables,
      data: {
        getMessagesOfConversation: {
          ...getMessagesOfConversation,
          items: newMessageList,
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateMessageInConversation;
