import { memo, useCallback, useRef, useState } from 'react';
import { Modal } from '@mui/material';
import { markTypes } from 'features/editor/constants/types';
import { Editor, Location, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { getMark } from '../mark/utils';
import ColorList from './components/colorList';
import { ArrowDownIcon, ColorIcon, ColorIconWrapper } from './styled';

const type = markTypes.COLOR;
const DEFAULT_COLOR_VALUE = 'transparent';

const ColorButton = () => {
  const editor = useSlate();
  const { selection } = editor;
  const selectionRef = useRef(selection) as React.MutableRefObject<Location>;

  const markColor: string = getMark(editor, type) || DEFAULT_COLOR_VALUE;
  const markColorRef = useRef(null) as React.MutableRefObject<
    typeof markColor | null
  >;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const showDialog: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      if (selection) {
        selectionRef.current = selection;
        markColorRef.current = markColor;
        setIsDialogOpen(true);
        Transforms.deselect(editor);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection],
  );

  const hideDialog = useCallback(() => {
    setIsDialogOpen(false);

    Transforms.select(editor, selectionRef.current);
    ReactEditor.focus(editor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColorSelection = useCallback(
    (selectedColorValue: string) => {
      hideDialog();

      if (selectedColorValue === DEFAULT_COLOR_VALUE) {
        try {
          Editor.removeMark(editor, type);
        } catch (error) {
          // console.log(error);
        }
      } else {
        try {
          Editor.addMark(editor, type, selectedColorValue);
        } catch (error) {
          // console.log(error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <ColorIconWrapper role="presentation" onMouseDown={showDialog}>
        <ColorIcon $markcolor={markColor} />
        <ArrowDownIcon />
      </ColorIconWrapper>
      <Modal
        open={isDialogOpen}
        onClose={hideDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <ColorList
          markColor={markColorRef.current as typeof markColor}
          onColorSelection={handleColorSelection}
        />
      </Modal>
    </>
  );
};

export default memo(ColorButton);
