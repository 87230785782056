import styled from '@emotion/styled';
import { ReactComponent as VideoIcon } from 'features/editor/assets/icons/Video_off.svg';

// eslint-disable-next-line import/prefer-default-export
export const StyledVideoIcon = styled(VideoIcon)`
  path {
    fill: ${(props) => props.theme.colors.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;
