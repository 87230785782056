import { css } from '@emotion/react';

const theme = {
  colors: {
    surfaceCard: '#323E4D',
    highEmphasis: '#ffffff',
    mediumEmphasis: 'rgba(255, 255, 255, 0.65)',
    approved: '#008A00',
    blackHoverOverlay: 'rgba(0, 0, 0, 0.08)',
    buttonBackgroundHighEmphasis: '#A837FF',
    timelineVideoClip: '#89B6F9',
    iconInactive: 'rgba(255, 255, 255, 0.54)',
    iconDisabled: 'rgba(255, 255, 255, 0.19)',
    blackMediumEmphasis: 'rgba(15, 20, 26, 0.7)',
    blackInactive: 'rgba(15, 20, 26, 0.54)',
    dividerLight: 'rgba(147, 157, 168, 0.2)',
    errorBackground: '#2D272F',
    borderOutlined: 'rgba(255, 255, 255, 0.25)',
    storyTimelineCurrentTimeIndicator: 'rgba(57, 151, 255, 0.35)',
    quoteBackground: 'rgba(255, 255, 255, 0.03)',
    onSelected: '#0A73EB',
    statusWarning: '#FF9800',
    statusApproved: '#008A00',
    statusError: '#DC3232',
    statusPitch: '#07D77F',
    statusStory: '#0A73EB',
    appBackgroundMain: 'rgba(24, 32, 40, 1)',
    appBackgroundNavLevel1: 'rgba(32, 42, 54, 1)',
    buttonOutlined: 'rgba(0, 0, 0, 0.08)',
    buttonBorderOutlined: 'rgba(255, 255, 255, 0.25)',
    borderResting: 'rgba(255, 255, 255, 0.08)',
    backgroundResting: 'rgba(0, 0, 0, 0.08)',
    boxShadow: 'rgba(0, 0, 0, 0.25)',
    onFocus: '#0A73EB',
    timelineGraphic: '#FFBE55',
    timelineSecondaryAccessory: '#ACB3BD',
  },
  typography: {
    h6: css`
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
    `,
    h7: css`
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    `,
    body2: css`
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
    `,
    caption: css`
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.25px;
    `,
    captionSmall: css`
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.25px;
    `,
    overline: css`
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    `,
    listItemLabel: css`
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    `,
    listItemLabelMedium: css`
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    `,
  },
};

export default theme;
