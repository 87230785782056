import React, { memo } from 'react';

import { outTimingTypes } from '../../../../utils/timingTypes';
import { MiddleEdge, RightEdge, RightEdgeWrapper } from './styled';

const { MANUAL_OUT } = outTimingTypes;

interface OutIconProps {
  outTimingType: string;
  isGraphic: boolean;
  isSelected: boolean;
}

const OutIconView = ({
  outTimingType = MANUAL_OUT,
  isGraphic = false,
  isSelected = false,
}: OutIconProps) => {
  const isManual = outTimingType === MANUAL_OUT;

  return (
    <RightEdgeWrapper>
      <RightEdge
        isManual={isManual}
        isGraphic={isGraphic}
        isSelected={isSelected}
      >
        <MiddleEdge outTimingType={outTimingType} />
      </RightEdge>
    </RightEdgeWrapper>
  );
};

export default memo(OutIconView);
