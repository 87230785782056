import { memo } from 'react';

import { Label, TitleText, TitleWrapper } from './styled';

const STAR_CG = 'starcg';

type fieldType = {
  index: number;
  value: string;
};

const joinSortedFieldValue = (_fields: fieldType[] = []) => {
  return _fields
    .sort((a: fieldType, b: fieldType) => a.index - b.index)
    .map((field) => field.value)
    .join('\n');
};

interface TitleProps {
  protocol?: string;
  templateVariant?: string;
  starcg?: {
    graphicsTemplateType: string;
    fields: unknown[];
  };
}

const Title = ({ protocol, templateVariant, starcg }: TitleProps) => {
  return (
    <TitleWrapper>
      {protocol === STAR_CG ? (
        <>
          <Label>Graphics Template Type</Label>
          <TitleText>{starcg?.graphicsTemplateType}</TitleText>
          <br />
          <Label>Text</Label>
          <TitleText>
            {joinSortedFieldValue(starcg?.fields as fieldType[])}
          </TitleText>
        </>
      ) : (
        <TitleText>{templateVariant}</TitleText>
      )}
    </TitleWrapper>
  );
};

export default memo(Title);
