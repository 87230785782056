import theme from 'theme';

const edgeColor = (isGraphic: boolean) =>
  theme.colors[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory'];

const borderColor = ({
  isGraphic,
  isSelected,
}: {
  isGraphic: boolean;
  isSelected: boolean;
}) => !isSelected && `1px solid ${edgeColor(isGraphic)}`;

export { edgeColor, borderColor };
