import React, { memo } from 'react';
import getThumbnailKey from 'features/editor/utils/getThumbnailKey';
import { RenderElementProps } from 'slate-react';

import { elementTypes } from '../../constants/types';
import Box, { ContentWrapper, Description, Title, TitleWrapper } from '../box';
import Thumbnail from '../image/components/thumbnail';
import { StyledVideoIcon } from './styled';

const Video = ({ attributes, element, children }: RenderElementProps) => {
  const {
    mId,
    mRefId,
    src,
    title: generatedTitle,
    metadata,
  } = element.data ?? {};
  const { title, description } = metadata ?? {};

  const key = src || getThumbnailKey(mId, mRefId);

  return (
    <div {...attributes}>
      {children}
      <Box type="media" Icon={StyledVideoIcon}>
        <ContentWrapper>
          <Thumbnail variant={elementTypes.VIDEO} thumbnailKey={key} />
          <TitleWrapper>
            {key ? (
              <Title>{title || generatedTitle}</Title>
            ) : (
              <Title italic>No Video added yet...</Title>
            )}
            {description && <Description>{description}</Description>}
          </TitleWrapper>
        </ContentWrapper>
      </Box>
    </div>
  );
};

export default memo(Video);
