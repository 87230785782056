import styled from '@emotion/styled';

interface IBaseWrapper {
  onMouseDown: () => void;
}

export const BaseWrapper = styled('div')<IBaseWrapper>`
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IButtonWrapper {
  isActive: boolean;
}
export const ButtonWrapper = styled('div')<IButtonWrapper>`
  height: 32px;
  width: 32px;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.iconDisabled : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  ${(props) =>
    props.isActive && {
      '& *': {
        fillOpacity: 1,
        fill: props.theme.colors.highEmphasis,
      },
    }}
`;
