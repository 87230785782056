export const checkIfString = (val: string) => typeof val === 'string';

const normalizeContent = (content: string) => {
  if (!content) return [];
  return content.split(',').map((val) => {
    const trimmedValue = val.trim();
    return { id: trimmedValue, value: trimmedValue };
  });
};

const getContent = (content: string | Record<string, string>) =>
  checkIfString(content as string)
    ? normalizeContent(content as string)
    : content;

export default getContent;
