import getTimeString from 'utils/getTimeString';

export type metadataType = Record<string, string>[];

const concatenateMetaValues = (metadata: metadataType): string => {
  if (metadata.length === 0) return '';

  return metadata.reduce<string>((mstr, mdata) => {
    if (!mdata.isVisible) return mstr;
    const updatedValue = mstr ? `${mstr}, ` : '';
    return `${updatedValue}${mdata.value}`;
  }, '');
};

export const getSubtitleString = (
  metadata: metadataType,
  asset: Record<string, string | number>,
): string => {
  let subTitle = concatenateMetaValues(metadata);
  if (asset) {
    if (subTitle) subTitle += ' | ';
    if (asset.mTitle) subTitle += asset.mTitle;
    else if (asset.title) subTitle += asset.title;
    if (asset.itemDuration)
      subTitle += ` | ${getTimeString(asset.itemDuration as number)}`;
  }
  return subTitle;
};
