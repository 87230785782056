import { memo } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as LiveStreamSVG } from 'features/editor/assets/icons/LiveStream.svg';
import { elementTypes } from 'features/editor/constants/types';
import { RenderElementProps } from 'slate-react';

import Box, { CollapsedLabel, RootWrapper, Title } from '../box';

const LiveStreamIcon = styled(LiveStreamSVG)`
  path {
    fill: ${(props) => props.theme.colors.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

const LiveStream = ({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = { type: elementTypes.LIVE_STREAM, children: [] },
}: RenderElementProps) => {
  const { mTitle, isCoverphoto = false } = element.data ?? {};

  return (
    <div {...attributes}>
      {children}
      <Box type={isCoverphoto ? 'cover' : 'media'} Icon={LiveStreamIcon}>
        <RootWrapper>
          <CollapsedLabel>Live Stream</CollapsedLabel>
          {mTitle ? (
            <Title>{mTitle}</Title>
          ) : (
            <Title italic>No content...</Title>
          )}
        </RootWrapper>
      </Box>
    </div>
  );
};

export default memo(LiveStream);
