import { elementTypes } from 'features/editor/constants/types';

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

/**
 * Determines if given type is list type or not
 *
 * @param type Type of the element node
 * @returns boolean value of whether it's a list or not
 */

const isList = ({ type }: { type: string }) =>
  [UNORDERED_LIST, ORDERED_LIST].includes(type);

export default isList;
